// ========== Keyframe

// Content animation
@keyframes fade-in {
	0%   {opacity: 0;}
	100% {opacity: 1;}
}
@keyframes fade-out {
	0%   {opacity: 1;}
	100% {opacity: 0; display: none;}
}
@keyframes fade-from-right {
	0%   {opacity: 0; transform: translateX(1em) translateY(0);}
	100% {opacity: 1; transform: translateX(0) translateY(0);}
}
@keyframes fade-from-left {
	0%   {opacity: 0; transform: translateX(-1em) translateY(0);}
	100% {opacity: 1; transform: translateX(0) translateY(0);}
}
@keyframes fade-from-top {
	0%   {opacity: 0; transform: translateX(0) translateY(-1em);}
	100% {opacity: 1; transform: translateX(0) translateY(0);}
}
@keyframes fade-from-bottom {
	0%   {opacity: 0; transform: translateX(0) translateY(1em);}
	100% {opacity: 1; transform: translateX(0) translateY(0);}
}

@keyframes slide-h {
	0% {left: 100%; width : 0;} 
	30% {left: 0; width : 100%;}
	70% {left: 0; width : 100%;}
	100% {left: 0; width : 0;}
}
@keyframes slide-v {
	0% {height : 0; width: 100%;} 
	// 30% {bottom: 0; height : 400px;}
	// 70% {bottom: 0; height : 400px;}
	100% {height: 100%; width: 100%;}
}


//--- Contact modal
@keyframes contact-modal {
	0%   {opacity: 0; visibility: visible; margin-top: 0; transform: translateX(0) translateY(-1em);}
	100% {opacity: 1; visibility: visible; margin-top: 0; transform: translateX(0) translateY(0);}
}
@keyframes contact-modal-off {
	0%   {opacity: 1; visibility: visible; margin-top: 0; transform: translateX(0) translateY(0);}
	100% {opacity: 0; visibility: visible; margin-top: 0; transform: translateX(0) translateY(1em);}
}

// ========== Divers
@keyframes teleport {
	0% {opacity: 1; transform: translateX(0);} 
	45% {opacity: 0; transform: translateX(50px);} 
	55% {opacity: 0; transform: translateX(300px);} 
	100% {opacity: 1; transform: translateX(350px);} 
}


/*
==================================================
====================== Home            			
==================================================
*/

//--- Header
@keyframes header-xlarge {
	0% {top : 0; left: 100%; width : 0;} 
	40% {left: 0; width : 100%;}
	60% {left: 0; width : 100%;}
	100% {left: 0; width : 29%;}
}
@keyframes header-large {
	0% {top : 0; left: 100%; width : 0;} 
	40% {left: 0; width : 100%;}
	60% {left: 0; width : 100%;}
	100% {left: 0; width : 33%;}
}
@keyframes header-medium {
	0% {top : 0; left: 100%; width : 0;} 
	40% {left: 0; width : 100%;}
	60% {left: 0; width : 100%;}
	100% {left: 0; width : 29%;}
}
@keyframes header-small{
	0% {top : 0; left: 100%; width : 0;} 
	40% {left: 0; width : 100%;}
	60% {left: 0; width : 100%;}
	100% {left: 0%; width : 0;}
}

//--- Portfolio
@keyframes cta-text{
	0% {opacity: 0; height: 100%; visibility: visible; transform: translateX(-1em) translateY(0);}
	100% {opacity: 1; height: 100%;visibility: visible; transform: translateX(0) translateY(0);}
}



/*
==================================================
====================== Boostravel            			
==================================================
*/


// ========== Plane animation

@keyframes cloud1 {
	0%   {top: -600px; left: 35%;}
	100% {top:-100px; left: -1000px;}
}
@keyframes cloud2 {
	0%   {top: -900px; left: 30%;}
	100% {top:-400px; left: -1000px;}
}
@keyframes cloud3 {
	0%   {top: -400px; left: 82%;}
	100% {top:200px; left: -800px;}
}
@keyframes cloud4 {
	0%   {top: 0px; left: 100%;}
	100% {top:500px; left: -1000px;}
}
@keyframes plane {
	0%		{transform: translateY(-10px) rotate(-25deg)  translate3d(0,0,0);}
	100%	{transform: translateY(10px) rotate(-25deg)  translate3d(0,0,0);}
}
@keyframes plane-shadow {
	0%		{transform: translateY(35px) scale(0.8) rotate(-25deg) translateZ(0); opacity: 0.1;}
	100%	{transform: translateY(30px) scale(1) rotate(-25deg) translateZ(0); opacity: 0.2;}
}

