/**
 * Owl Carousel v2.2.0
 * Copyright 2013-2016 David Deutsch
 * Licensed under MIT (https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE)
 */
.owl-carousel, .owl-carousel .owl-item {
  -webkit-tap-highlight-color: transparent;
  position: relative; }

.owl-carousel {
  display: none;
  width: 100%;
  z-index: 1; }

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y; }

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0); }

.owl-carousel .owl-item {
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-touch-callout: none; }

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
  -webkit-transform-style: preserve-3d; }

.owl-carousel .owl-dots.disabled, .owl-carousel .owl-nav.disabled {
  display: none; }

.no-js .owl-carousel, .owl-carousel.owl-loaded {
  display: block; }

.owl-carousel .owl-dot, .owl-carousel .owl-nav .owl-next, .owl-carousel .owl-nav .owl-prev {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.owl-carousel.owl-loading {
  opacity: 0;
  display: block; }

.owl-carousel.owl-hidden {
  opacity: 0; }

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden; }

.owl-carousel.owl-drag .owl-item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab; }

.owl-carousel.owl-rtl {
  direction: rtl; }

.owl-carousel.owl-rtl .owl-item {
  float: right; }

.owl-carousel .animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

.owl-carousel .owl-animated-in {
  z-index: 0; }

.owl-carousel .owl-animated-out {
  z-index: 1; }

.owl-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut; }

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.owl-height {
  transition: height .5s ease-in-out; }

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity .4s ease; }

.owl-carousel .owl-item img.owl-lazy {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d; }

.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000; }

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url(owl.video.play.png) no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: -webkit-transform .1s ease;
  transition: transform .1s ease; }

.owl-carousel .owl-video-play-icon:hover {
  -webkit-transform: scale(1.3, 1.3);
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3); }

.owl-carousel .owl-video-playing .owl-video-play-icon, .owl-carousel .owl-video-playing .owl-video-tn {
  display: none; }

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity .4s ease; }

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%; }

/**
 * Owl Carousel v2.2.0
 * Copyright 2013-2016 David Deutsch
 * Licensed under MIT (https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE)
 */
.owl-theme .owl-dots, .owl-theme .owl-nav {
  text-align: center;
  -webkit-tap-highlight-color: transparent; }

.owl-theme .owl-nav {
  margin-top: 10px; }

.owl-theme .owl-nav [class*=owl-] {
  color: #FFF;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: #D6D6D6;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px; }

.owl-theme .owl-nav [class*=owl-]:hover {
  background: #869791;
  color: #FFF;
  text-decoration: none; }

.owl-theme .owl-nav .disabled {
  opacity: .5;
  cursor: default; }

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px; }

.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1; }

.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #D6D6D6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity .2s ease;
  border-radius: 30px; }

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #869791; }

/**
 * Foundation for Sites by ZURB
 * Version 6.3.0
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
/* montserrat-200 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  src: url("../fonts/montserratmontserrat-v10-latin-200.eot");
  /* IE9 Compat Modes */
  src: local("Montserrat ExtraLight"), local("Montserrat-ExtraLight"), url("../fonts/montserratmontserrat-v10-latin-200.eot?#iefix") format("embedded-opentype"), url("../fonts/montserratmontserrat-v10-latin-200.woff2") format("woff2"), url("../fonts/montserratmontserrat-v10-latin-200.woff") format("woff"), url("../fonts/montserratmontserrat-v10-latin-200.ttf") format("truetype"), url("../fonts/montserratmontserrat-v10-latin-200.svg#Montserrat") format("svg");
  /* Legacy iOS */ }

/* montserrat-300 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/montserratmontserrat-v10-latin-300.eot");
  /* IE9 Compat Modes */
  src: local("Montserrat Light"), local("Montserrat-Light"), url("../fonts/montserratmontserrat-v10-latin-300.eot?#iefix") format("embedded-opentype"), url("../fonts/montserratmontserrat-v10-latin-300.woff2") format("woff2"), url("../fonts/montserratmontserrat-v10-latin-300.woff") format("woff"), url("../fonts/montserratmontserrat-v10-latin-300.ttf") format("truetype"), url("../fonts/montserratmontserrat-v10-latin-300.svg#Montserrat") format("svg");
  /* Legacy iOS */ }

/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/montserratmontserrat-v10-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Montserrat Regular"), local("Montserrat-Regular"), url("../fonts/montserratmontserrat-v10-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/montserratmontserrat-v10-latin-regular.woff2") format("woff2"), url("../fonts/montserratmontserrat-v10-latin-regular.woff") format("woff"), url("../fonts/montserratmontserrat-v10-latin-regular.ttf") format("truetype"), url("../fonts/montserratmontserrat-v10-latin-regular.svg#Montserrat") format("svg");
  /* Legacy iOS */ }

/* montserrat-500 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/montserratmontserrat-v10-latin-500.eot");
  /* IE9 Compat Modes */
  src: local("Montserrat Medium"), local("Montserrat-Medium"), url("../fonts/montserratmontserrat-v10-latin-500.eot?#iefix") format("embedded-opentype"), url("../fonts/montserratmontserrat-v10-latin-500.woff2") format("woff2"), url("../fonts/montserratmontserrat-v10-latin-500.woff") format("woff"), url("../fonts/montserratmontserrat-v10-latin-500.ttf") format("truetype"), url("../fonts/montserratmontserrat-v10-latin-500.svg#Montserrat") format("svg");
  /* Legacy iOS */ }

/* montserrat-600 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/montserratmontserrat-v10-latin-600.eot");
  /* IE9 Compat Modes */
  src: local("Montserrat SemiBold"), local("Montserrat-SemiBold"), url("../fonts/montserratmontserrat-v10-latin-600.eot?#iefix") format("embedded-opentype"), url("../fonts/montserratmontserrat-v10-latin-600.woff2") format("woff2"), url("../fonts/montserratmontserrat-v10-latin-600.woff") format("woff"), url("../fonts/montserratmontserrat-v10-latin-600.ttf") format("truetype"), url("../fonts/montserratmontserrat-v10-latin-600.svg#Montserrat") format("svg");
  /* Legacy iOS */ }

/* montserrat-700 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/montserratmontserrat-v10-latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Montserrat Bold"), local("Montserrat-Bold"), url("../fonts/montserratmontserrat-v10-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/montserratmontserrat-v10-latin-700.woff2") format("woff2"), url("../fonts/montserratmontserrat-v10-latin-700.woff") format("woff"), url("../fonts/montserratmontserrat-v10-latin-700.ttf") format("truetype"), url("../fonts/montserratmontserrat-v10-latin-700.svg#Montserrat") format("svg");
  /* Legacy iOS */ }

/* montserrat-800 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/montserratmontserrat-v10-latin-800.eot");
  /* IE9 Compat Modes */
  src: local("Montserrat ExtraBold"), local("Montserrat-ExtraBold"), url("../fonts/montserratmontserrat-v10-latin-800.eot?#iefix") format("embedded-opentype"), url("../fonts/montserratmontserrat-v10-latin-800.woff2") format("woff2"), url("../fonts/montserratmontserrat-v10-latin-800.woff") format("woff"), url("../fonts/montserratmontserrat-v10-latin-800.ttf") format("truetype"), url("../fonts/montserratmontserrat-v10-latin-800.svg#Montserrat") format("svg");
  /* Legacy iOS */ }

/* montserrat-900 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/montserratmontserrat-v10-latin-900.eot");
  /* IE9 Compat Modes */
  src: local("Montserrat Black"), local("Montserrat-Black"), url("../fonts/montserratmontserrat-v10-latin-900.eot?#iefix") format("embedded-opentype"), url("../fonts/montserratmontserrat-v10-latin-900.woff2") format("woff2"), url("../fonts/montserratmontserrat-v10-latin-900.woff") format("woff"), url("../fonts/montserratmontserrat-v10-latin-900.ttf") format("truetype"), url("../fonts/montserratmontserrat-v10-latin-900.svg#Montserrat") format("svg");
  /* Legacy iOS */ }

/* titillium-web-300 - latin */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/titillium/titillium-web-v4-latin-300.eot");
  /* IE9 Compat Modes */
  src: local("Titillium WebLight"), local("TitilliumWeb-Light"), url("../fonts/titillium/titillium-web-v4-latin-300.eot?#iefix") format("embedded-opentype"), url("../fonts/titillium/titillium-web-v4-latin-300.woff2") format("woff2"), url("../fonts/titillium/titillium-web-v4-latin-300.woff") format("woff"), url("../fonts/titillium/titillium-web-v4-latin-300.ttf") format("truetype"), url("../fonts/titillium/titillium-web-v4-latin-300.svg#TitilliumWeb") format("svg");
  /* Legacy iOS */ }

/* titillium-web-regular - latin */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/titillium/titillium-web-v4-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Titillium Web"), local("TitilliumWeb-Regular"), url("../fonts/titillium/titillium-web-v4-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/titillium/titillium-web-v4-latin-regular.woff2") format("woff2"), url("../fonts/titillium/titillium-web-v4-latin-regular.woff") format("woff"), url("../fonts/titillium/titillium-web-v4-latin-regular.ttf") format("truetype"), url("../fonts/titillium/titillium-web-v4-latin-regular.svg#TitilliumWeb") format("svg");
  /* Legacy iOS */ }

/* titillium-web-600 - latin */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/titillium/titillium-web-v4-latin-600.eot");
  /* IE9 Compat Modes */
  src: local("Titillium WebSemiBold"), local("TitilliumWeb-SemiBold"), url("../fonts/titillium/titillium-web-v4-latin-600.eot?#iefix") format("embedded-opentype"), url("../fonts/titillium/titillium-web-v4-latin-600.woff2") format("woff2"), url("../fonts/titillium/titillium-web-v4-latin-600.woff") format("woff"), url("../fonts/titillium/titillium-web-v4-latin-600.ttf") format("truetype"), url("../fonts/titillium/titillium-web-v4-latin-600.svg#TitilliumWeb") format("svg");
  /* Legacy iOS */ }

/* titillium-web-700 - latin */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/titillium/titillium-web-v4-latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Titillium WebBold"), local("TitilliumWeb-Bold"), url("../fonts/titillium/titillium-web-v4-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/titillium/titillium-web-v4-latin-700.woff2") format("woff2"), url("../fonts/titillium/titillium-web-v4-latin-700.woff") format("woff"), url("../fonts/titillium/titillium-web-v4-latin-700.ttf") format("truetype"), url("../fonts/titillium/titillium-web-v4-latin-700.svg#TitilliumWeb") format("svg");
  /* Legacy iOS */ }

/* titillium-web-900 - latin */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/titillium/titillium-web-v4-latin-900.eot");
  /* IE9 Compat Modes */
  src: local("Titillium WebBlack"), local("TitilliumWeb-Black"), url("../fonts/titillium/titillium-web-v4-latin-900.eot?#iefix") format("embedded-opentype"), url("../fonts/titillium/titillium-web-v4-latin-900.woff2") format("woff2"), url("../fonts/titillium/titillium-web-v4-latin-900.woff") format("woff"), url("../fonts/titillium/titillium-web-v4-latin-900.ttf") format("truetype"), url("../fonts/titillium/titillium-web-v4-latin-900.svg#TitilliumWeb") format("svg");
  /* Legacy iOS */ }

/* source-serif-pro-regular - latin */
@font-face {
  font-family: 'SourceSerifPro';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/source-serifsource-serif-pro-v4-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Source Serif Pro"), local("SourceSerifPro-Regular"), url("../fonts/source-serifsource-serif-pro-v4-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/source-serifsource-serif-pro-v4-latin-regular.woff2") format("woff2"), url("../fonts/source-serifsource-serif-pro-v4-latin-regular.woff") format("woff"), url("../fonts/source-serifsource-serif-pro-v4-latin-regular.ttf") format("truetype"), url("../fonts/source-serifsource-serif-pro-v4-latin-regular.svg#SourceSerifPro") format("svg");
  /* Legacy iOS */ }

/* source-serif-pro-600 - latin */
@font-face {
  font-family: 'SourceSerifPro';
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/source-serifsource-serif-pro-v4-latin-600.eot");
  /* IE9 Compat Modes */
  src: local("Source Serif Pro Semibold"), local("SourceSerifPro-Semibold"), url("../fonts/source-serifsource-serif-pro-v4-latin-600.eot?#iefix") format("embedded-opentype"), url("../fonts/source-serifsource-serif-pro-v4-latin-600.woff2") format("woff2"), url("../fonts/source-serifsource-serif-pro-v4-latin-600.woff") format("woff"), url("../fonts/source-serifsource-serif-pro-v4-latin-600.ttf") format("truetype"), url("../fonts/source-serifsource-serif-pro-v4-latin-600.svg#SourceSerifPro") format("svg");
  /* Legacy iOS */ }

/* source-serif-pro-700 - latin */
@font-face {
  font-family: 'SourceSerifPro';
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/source-serifsource-serif-pro-v4-latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Source Serif Pro Bold"), local("SourceSerifPro-Bold"), url("../fonts/source-serifsource-serif-pro-v4-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/source-serifsource-serif-pro-v4-latin-700.woff2") format("woff2"), url("../fonts/source-serifsource-serif-pro-v4-latin-700.woff") format("woff"), url("../fonts/source-serifsource-serif-pro-v4-latin-700.ttf") format("truetype"), url("../fonts/source-serifsource-serif-pro-v4-latin-700.svg#SourceSerifPro") format("svg");
  /* Legacy iOS */ }

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot?n8flav");
  src: url("../fonts/icomoon.eot?n8flav#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?n8flav") format("truetype"), url("../fonts/icomoon.woff?n8flav") format("woff"), url("../fonts/icomoon.svg?n8flav#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-ico-ux:before {
  content: "\e909"; }

.icon-logo-3kdesign:before {
  content: "\e912"; }

.icon-ico-behance:before {
  content: "\e900"; }

.icon-ico-boostravel:before {
  content: "\e901"; }

.icon-ico-burger:before {
  content: "\e902"; }

.icon-ico-citydrive:before {
  content: "\e903"; }

.icon-ico-consulting:before {
  content: "\e904"; }

.icon-ico-development:before {
  content: "\e905"; }

.icon-ico-dribbble:before {
  content: "\e906"; }

.icon-ico-Gemalto:before {
  content: "\e907"; }

.icon-ico-linkedin:before {
  content: "\e908"; }

.icon-ico-meteofrance:before {
  content: "\e90a"; }

.icon-ico-orange:before {
  content: "\e90b"; }

.icon-ico-otorim:before {
  content: "\e90c"; }

.icon-ico-peugeot:before {
  content: "\e90d"; }

.icon-ico-plus:before {
  content: "\e90e"; }

.icon-ico-publicis:before {
  content: "\e90f"; }

.icon-ico-twitter:before {
  content: "\e910"; }

.icon-ico-webdesign:before {
  content: "\e911"; }

@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-out {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    display: none; } }

@keyframes fade-from-right {
  0% {
    opacity: 0;
    transform: translateX(1em) translateY(0); }
  100% {
    opacity: 1;
    transform: translateX(0) translateY(0); } }

@keyframes fade-from-left {
  0% {
    opacity: 0;
    transform: translateX(-1em) translateY(0); }
  100% {
    opacity: 1;
    transform: translateX(0) translateY(0); } }

@keyframes fade-from-top {
  0% {
    opacity: 0;
    transform: translateX(0) translateY(-1em); }
  100% {
    opacity: 1;
    transform: translateX(0) translateY(0); } }

@keyframes fade-from-bottom {
  0% {
    opacity: 0;
    transform: translateX(0) translateY(1em); }
  100% {
    opacity: 1;
    transform: translateX(0) translateY(0); } }

@keyframes slide-h {
  0% {
    left: 100%;
    width: 0; }
  30% {
    left: 0;
    width: 100%; }
  70% {
    left: 0;
    width: 100%; }
  100% {
    left: 0;
    width: 0; } }

@keyframes slide-v {
  0% {
    height: 0;
    width: 100%; }
  100% {
    height: 100%;
    width: 100%; } }

@keyframes contact-modal {
  0% {
    opacity: 0;
    visibility: visible;
    margin-top: 0;
    transform: translateX(0) translateY(-1em); }
  100% {
    opacity: 1;
    visibility: visible;
    margin-top: 0;
    transform: translateX(0) translateY(0); } }

@keyframes contact-modal-off {
  0% {
    opacity: 1;
    visibility: visible;
    margin-top: 0;
    transform: translateX(0) translateY(0); }
  100% {
    opacity: 0;
    visibility: visible;
    margin-top: 0;
    transform: translateX(0) translateY(1em); } }

@keyframes teleport {
  0% {
    opacity: 1;
    transform: translateX(0); }
  45% {
    opacity: 0;
    transform: translateX(50px); }
  55% {
    opacity: 0;
    transform: translateX(300px); }
  100% {
    opacity: 1;
    transform: translateX(350px); } }

/*
==================================================
====================== Home            			
==================================================
*/
@keyframes header-xlarge {
  0% {
    top: 0;
    left: 100%;
    width: 0; }
  40% {
    left: 0;
    width: 100%; }
  60% {
    left: 0;
    width: 100%; }
  100% {
    left: 0;
    width: 29%; } }

@keyframes header-large {
  0% {
    top: 0;
    left: 100%;
    width: 0; }
  40% {
    left: 0;
    width: 100%; }
  60% {
    left: 0;
    width: 100%; }
  100% {
    left: 0;
    width: 33%; } }

@keyframes header-medium {
  0% {
    top: 0;
    left: 100%;
    width: 0; }
  40% {
    left: 0;
    width: 100%; }
  60% {
    left: 0;
    width: 100%; }
  100% {
    left: 0;
    width: 29%; } }

@keyframes header-small {
  0% {
    top: 0;
    left: 100%;
    width: 0; }
  40% {
    left: 0;
    width: 100%; }
  60% {
    left: 0;
    width: 100%; }
  100% {
    left: 0%;
    width: 0; } }

@keyframes cta-text {
  0% {
    opacity: 0;
    height: 100%;
    visibility: visible;
    transform: translateX(-1em) translateY(0); }
  100% {
    opacity: 1;
    height: 100%;
    visibility: visible;
    transform: translateX(0) translateY(0); } }

/*
==================================================
====================== Boostravel            			
==================================================
*/
@keyframes cloud1 {
  0% {
    top: -600px;
    left: 35%; }
  100% {
    top: -100px;
    left: -1000px; } }

@keyframes cloud2 {
  0% {
    top: -900px;
    left: 30%; }
  100% {
    top: -400px;
    left: -1000px; } }

@keyframes cloud3 {
  0% {
    top: -400px;
    left: 82%; }
  100% {
    top: 200px;
    left: -800px; } }

@keyframes cloud4 {
  0% {
    top: 0px;
    left: 100%; }
  100% {
    top: 500px;
    left: -1000px; } }

@keyframes plane {
  0% {
    transform: translateY(-10px) rotate(-25deg) translate3d(0, 0, 0); }
  100% {
    transform: translateY(10px) rotate(-25deg) translate3d(0, 0, 0); } }

@keyframes plane-shadow {
  0% {
    transform: translateY(35px) scale(0.8) rotate(-25deg) translateZ(0);
    opacity: 0.1; }
  100% {
    transform: translateY(30px) scale(1) rotate(-25deg) translateZ(0);
    opacity: 0.2; } }

/**********************
 *CSS Animations by:
 *http://codepen.io/vivinantony
***********************/
.spinner1 {
  width: 40px;
  height: 40px;
  position: relative; }

.double-bounce1, .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #fff;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: bounce 2.0s infinite ease-in-out;
  animation: bounce 2.0s infinite ease-in-out; }

.double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s; }

@-webkit-keyframes bounce {
  0%, 100% {
    -webkit-transform: scale(0); }
  50% {
    -webkit-transform: scale(1); } }

@keyframes bounce {
  0%, 100% {
    transform: scale(0);
    -webkit-transform: scale(0); }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1); } }

.spinner2 {
  width: 40px;
  height: 40px;
  position: relative; }

.container1 > div, .container2 > div, .container3 > div {
  width: 6px;
  height: 6px;
  background-color: #fff;
  border-radius: 100%;
  position: absolute;
  -webkit-animation: bouncedelay 1.2s infinite ease-in-out;
  animation: bouncedelay 1.2s infinite ease-in-out;
  /* Prevent first frame from flickering when animation starts */
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

.spinner2 .spinner-container {
  position: absolute;
  width: 100%;
  height: 100%; }

.container2 {
  -webkit-transform: rotateZ(45deg);
  transform: rotateZ(45deg); }

.container3 {
  -webkit-transform: rotateZ(90deg);
  transform: rotateZ(90deg); }

.circle1 {
  top: 0;
  left: 0; }

.circle2 {
  top: 0;
  right: 0; }

.circle3 {
  right: 0;
  bottom: 0; }

.circle4 {
  left: 0;
  bottom: 0; }

.container2 .circle1 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s; }

.container3 .circle1 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s; }

.container1 .circle2 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s; }

.container2 .circle2 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s; }

.container3 .circle2 {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s; }

.container1 .circle3 {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s; }

.container2 .circle3 {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s; }

.container3 .circle3 {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s; }

.container1 .circle4 {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s; }

.container2 .circle4 {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s; }

.container3 .circle4 {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s; }

@-webkit-keyframes bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0); }
  40% {
    -webkit-transform: scale(1); } }

@keyframes bouncedelay {
  0%, 80%, 100% {
    transform: scale(0);
    -webkit-transform: scale(0); }
  40% {
    transform: scale(1);
    -webkit-transform: scale(1); } }

.spinner3 {
  width: 40px;
  height: 40px;
  position: relative;
  -webkit-animation: rotate 2.0s infinite linear;
  animation: rotate 2.0s infinite linear; }

.dot1, .dot2 {
  width: 60%;
  height: 60%;
  display: inline-block;
  position: absolute;
  top: 0;
  background-color: #fff;
  border-radius: 100%;
  -webkit-animation: bounce 2.0s infinite ease-in-out;
  animation: bounce 2.0s infinite ease-in-out; }

.dot2 {
  top: auto;
  bottom: 0px;
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s; }

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes rotate {
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg); } }

@-webkit-keyframes bounce {
  0%, 100% {
    -webkit-transform: scale(0); }
  50% {
    -webkit-transform: scale(1); } }

@keyframes bounce {
  0%, 100% {
    transform: scale(0);
    -webkit-transform: scale(0); }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1); } }

.spinner4 {
  width: 30px;
  height: 30px;
  background-color: #fff;
  -webkit-animation: rotateplane 1.2s infinite ease-in-out;
  animation: rotateplane 1.2s infinite ease-in-out; }

@-webkit-keyframes rotateplane {
  0% {
    -webkit-transform: perspective(120px); }
  50% {
    -webkit-transform: perspective(120px) rotateY(180deg); }
  100% {
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg); } }

@keyframes rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg); }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg); }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg); } }

.spinner5 {
  width: 32px;
  height: 32px;
  position: relative; }

.cube1, .cube2 {
  background-color: #fff;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: cubemove 1.8s infinite ease-in-out;
  animation: cubemove 1.8s infinite ease-in-out; }

.cube2 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s; }

@-webkit-keyframes cubemove {
  25% {
    -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5); }
  50% {
    -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg); }
  75% {
    -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5); }
  100% {
    -webkit-transform: rotate(-360deg); } }

@keyframes cubemove {
  25% {
    transform: translateX(42px) rotate(-90deg) scale(0.5);
    -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5); }
  50% {
    transform: translateX(42px) translateY(42px) rotate(-179deg);
    -webkit-transform: translateX(42px) translateY(42px) rotate(-179deg); }
  50.1% {
    transform: translateX(42px) translateY(42px) rotate(-180deg);
    -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg); }
  75% {
    transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
    -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5); }
  100% {
    transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg); } }

.spinner6 {
  width: 50px;
  height: 30px;
  text-align: center; }

.spinner6 > div {
  background-color: #fff;
  height: 100%;
  width: 6px;
  margin-left: 2px;
  display: inline-block;
  -webkit-animation: stretchdelay 1.2s infinite ease-in-out;
  animation: stretchdelay 1.2s infinite ease-in-out; }

.spinner6 .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s; }

.spinner6 .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s; }

.spinner6 .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s; }

.spinner6 .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s; }

@-webkit-keyframes stretchdelay {
  0%, 40%, 100% {
    -webkit-transform: scaleY(0.4); }
  20% {
    -webkit-transform: scaleY(1); } }

@keyframes stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4); }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1); } }

.spinner7 {
  width: 90px;
  height: 30px;
  text-align: center; }

.spinner7 > div {
  background-color: #fff;
  height: 15px;
  width: 15px;
  margin-left: 3px;
  border-radius: 50%;
  display: inline-block;
  -webkit-animation: stretchdelay 0.7s infinite ease-in-out;
  animation: stretchdelay 0.7s infinite ease-in-out; }

.spinner7 .circ2 {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s; }

.spinner7 .circ3 {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s; }

.spinner7 .circ4 {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s; }

.spinner7 .circ5 {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s; }

@-webkit-keyframes stretchdelay {
  0%, 40%, 100% {
    -webkit-transform: translateY(-10px); }
  20% {
    -webkit-transform: translateY(-20px); } }

@keyframes stretchdelay {
  0%, 40%, 100% {
    transform: translateY(-10px);
    -webkit-transform: translateY(-10px); }
  20% {
    transform: translateY(-20px);
    -webkit-transform: translateY(-20px); } }

/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1, .cs-contact-modal h3, .cs-menu-modal h3 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

.foundation-mq {
  font-family: "small=0em&xsmall=30em&medium=40em&xmedium=52.5em&large=64em&xlarge=75em&xxlarge=90em&xxxlarge=100em"; }

html {
  box-sizing: border-box;
  font-size: 100%; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  background: #fefefe;
  font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic; }

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 0; }

select {
  width: 100%;
  border-radius: 0; }

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important; }

button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1; }
  [data-whatinput='mouse'] button {
    outline: 0; }

.is-visible {
  display: block !important; }

.is-hidden {
  display: none !important; }

.row {
  max-width: 120rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap; }
  .row .row {
    margin-right: -0.75rem;
    margin-left: -0.75rem; }
    @media print, screen and (min-width: 30em) {
      .row .row {
        margin-right: -0.75rem;
        margin-left: -0.75rem; } }
    @media print, screen and (min-width: 40em) {
      .row .row {
        margin-right: -1rem;
        margin-left: -1rem; } }
    @media print, screen and (min-width: 52.5em) {
      .row .row {
        margin-right: -1rem;
        margin-left: -1rem; } }
    @media print, screen and (min-width: 64em) {
      .row .row {
        margin-right: -1rem;
        margin-left: -1rem; } }
    @media screen and (min-width: 75em) {
      .row .row {
        margin-right: -1rem;
        margin-left: -1rem; } }
    @media screen and (min-width: 90em) {
      .row .row {
        margin-right: -1rem;
        margin-left: -1rem; } }
    @media screen and (min-width: 100em) {
      .row .row {
        margin-right: -1rem;
        margin-left: -1rem; } }
  .row.expanded {
    max-width: none; }
  .row.collapse > .column, .row.collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .row.is-collapse-child,
  .row.collapse > .column > .row,
  .row.collapse > .columns > .row {
    margin-right: 0;
    margin-left: 0; }

.column, .columns {
  flex: 1 1 0px;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  min-width: initial; }
  @media print, screen and (min-width: 40em) {
    .column, .columns {
      padding-right: 1rem;
      padding-left: 1rem; } }

.column.row.row, .row.row.columns {
  display: flex; }

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0; }

.flex-container {
  display: flex; }

.flex-child-auto {
  flex: 1 1 auto; }

.flex-child-grow {
  flex: 1 0 auto; }

.flex-child-shrink {
  flex: 0 1 auto; }

.flex-dir-row {
  flex-direction: row; }

.flex-dir-row-reverse {
  flex-direction: row-reverse; }

.flex-dir-column {
  flex-direction: column; }

.flex-dir-column-reverse {
  flex-direction: column-reverse; }

.small-1 {
  flex: 0 0 4.16667%;
  max-width: 4.16667%; }

.small-offset-0 {
  margin-left: 0%; }

.small-2 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.small-offset-1 {
  margin-left: 4.16667%; }

.small-3 {
  flex: 0 0 12.5%;
  max-width: 12.5%; }

.small-offset-2 {
  margin-left: 8.33333%; }

.small-4 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.small-offset-3 {
  margin-left: 12.5%; }

.small-5 {
  flex: 0 0 20.83333%;
  max-width: 20.83333%; }

.small-offset-4 {
  margin-left: 16.66667%; }

.small-6 {
  flex: 0 0 25%;
  max-width: 25%; }

.small-offset-5 {
  margin-left: 20.83333%; }

.small-7 {
  flex: 0 0 29.16667%;
  max-width: 29.16667%; }

.small-offset-6 {
  margin-left: 25%; }

.small-8 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.small-offset-7 {
  margin-left: 29.16667%; }

.small-9 {
  flex: 0 0 37.5%;
  max-width: 37.5%; }

.small-offset-8 {
  margin-left: 33.33333%; }

.small-10 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.small-offset-9 {
  margin-left: 37.5%; }

.small-11 {
  flex: 0 0 45.83333%;
  max-width: 45.83333%; }

.small-offset-10 {
  margin-left: 41.66667%; }

.small-12, .cs-logo {
  flex: 0 0 50%;
  max-width: 50%; }

.small-offset-11 {
  margin-left: 45.83333%; }

.small-13 {
  flex: 0 0 54.16667%;
  max-width: 54.16667%; }

.small-offset-12 {
  margin-left: 50%; }

.small-14 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.small-offset-13 {
  margin-left: 54.16667%; }

.small-15 {
  flex: 0 0 62.5%;
  max-width: 62.5%; }

.small-offset-14 {
  margin-left: 58.33333%; }

.small-16, .ui .assets .colors .columns {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.small-offset-15 {
  margin-left: 62.5%; }

.small-17 {
  flex: 0 0 70.83333%;
  max-width: 70.83333%; }

.small-offset-16 {
  margin-left: 66.66667%; }

.small-18, .ui .assets .fonts {
  flex: 0 0 75%;
  max-width: 75%; }

.small-offset-17 {
  margin-left: 70.83333%; }

.small-19 {
  flex: 0 0 79.16667%;
  max-width: 79.16667%; }

.small-offset-18 {
  margin-left: 75%; }

.small-20 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.small-offset-19 {
  margin-left: 79.16667%; }

.small-21 {
  flex: 0 0 87.5%;
  max-width: 87.5%; }

.small-offset-20 {
  margin-left: 83.33333%; }

.small-22 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.small-offset-21 {
  margin-left: 87.5%; }

.small-23 {
  flex: 0 0 95.83333%;
  max-width: 95.83333%; }

.small-offset-22 {
  margin-left: 91.66667%; }

.small-24, .cs-profil, .cs-profil .quote-01.active, .cs-profil .quote-02.active, .cs-profil .quote-03.active, .cs-profil .quote-04.active, .cs-profil .quote-end.active, .cs-intro .cs-devices, .cs-intro .cs-devices-mobile, .skills .consulting, .skills .webdesign, .skills .development, .clients .block-logos, .clients .reviews, .clients .reviews .avatar, .cs-portfolio-home .cs-boostravel, .cs-portfolio-home .cs-otorim, .cs-portfolio-home .cs-citydrive, .bs-detail, .bs-magazine .text, .bs-skills .skill-list, .bs-skills .skill-list ul .columns, .ui .assets .colors {
  flex: 0 0 100%;
  max-width: 100%; }

.small-offset-23 {
  margin-left: 95.83333%; }

.small-order-1, .cs-slide, .bs-visual {
  order: 1; }

.small-order-2, .cs-profil, .bs-detail {
  order: 2; }

.small-order-3 {
  order: 3; }

.small-order-4 {
  order: 4; }

.small-order-5 {
  order: 5; }

.small-order-6 {
  order: 6; }

.small-up-1 {
  flex-wrap: wrap; }
  .small-up-1 > .column, .small-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%; }

.small-up-2 {
  flex-wrap: wrap; }
  .small-up-2 > .column, .small-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%; }

.small-up-3 {
  flex-wrap: wrap; }
  .small-up-3 > .column, .small-up-3 > .columns {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }

.small-up-4 {
  flex-wrap: wrap; }
  .small-up-4 > .column, .small-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%; }

.small-up-5 {
  flex-wrap: wrap; }
  .small-up-5 > .column, .small-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%; }

.small-up-6 {
  flex-wrap: wrap; }
  .small-up-6 > .column, .small-up-6 > .columns {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }

.small-up-7 {
  flex-wrap: wrap; }
  .small-up-7 > .column, .small-up-7 > .columns {
    flex: 0 0 14.28571%;
    max-width: 14.28571%; }

.small-up-8 {
  flex-wrap: wrap; }
  .small-up-8 > .column, .small-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%; }

.small-collapse > .column, .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0; }

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

@media print, screen and (min-width: 30em) {
  .xsmall-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .xsmall-offset-0 {
    margin-left: 0%; }
  .xsmall-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .xsmall-offset-1 {
    margin-left: 4.16667%; }
  .xsmall-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .xsmall-offset-2 {
    margin-left: 8.33333%; }
  .xsmall-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .xsmall-offset-3 {
    margin-left: 12.5%; }
  .xsmall-5, .cs-logo {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .xsmall-offset-4 {
    margin-left: 16.66667%; }
  .xsmall-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .xsmall-offset-5 {
    margin-left: 20.83333%; }
  .xsmall-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .xsmall-offset-6 {
    margin-left: 25%; }
  .xsmall-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .xsmall-offset-7 {
    margin-left: 29.16667%; }
  .xsmall-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .xsmall-offset-8 {
    margin-left: 33.33333%; }
  .xsmall-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .xsmall-offset-9 {
    margin-left: 37.5%; }
  .xsmall-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .xsmall-offset-10 {
    margin-left: 41.66667%; }
  .xsmall-12, .ui .assets .colors .columns {
    flex: 0 0 50%;
    max-width: 50%; }
  .xsmall-offset-11 {
    margin-left: 45.83333%; }
  .xsmall-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .xsmall-offset-12 {
    margin-left: 50%; }
  .xsmall-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .xsmall-offset-13 {
    margin-left: 54.16667%; }
  .xsmall-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .xsmall-offset-14 {
    margin-left: 58.33333%; }
  .xsmall-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .xsmall-offset-15 {
    margin-left: 62.5%; }
  .xsmall-17, .cs-portfolio-home .cs-otorim a.cs-infos .text {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .xsmall-offset-16 {
    margin-left: 66.66667%; }
  .xsmall-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .xsmall-offset-17 {
    margin-left: 70.83333%; }
  .xsmall-19, .cs-portfolio-home .cs-boostravel a.cs-infos .text, .cs-portfolio-home .cs-citydrive a.cs-infos .text {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .xsmall-offset-18 {
    margin-left: 75%; }
  .xsmall-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .xsmall-offset-19 {
    margin-left: 79.16667%; }
  .xsmall-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .xsmall-offset-20 {
    margin-left: 83.33333%; }
  .xsmall-22, .ui .assets .colors {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .xsmall-offset-21 {
    margin-left: 87.5%; }
  .xsmall-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .xsmall-offset-22 {
    margin-left: 91.66667%; }
  .xsmall-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .xsmall-offset-23 {
    margin-left: 95.83333%; }
  .xsmall-order-1 {
    order: 1; }
  .xsmall-order-2 {
    order: 2; }
  .xsmall-order-3 {
    order: 3; }
  .xsmall-order-4 {
    order: 4; }
  .xsmall-order-5 {
    order: 5; }
  .xsmall-order-6 {
    order: 6; }
  .xsmall-up-1 {
    flex-wrap: wrap; }
    .xsmall-up-1 > .column, .xsmall-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }
  .xsmall-up-2 {
    flex-wrap: wrap; }
    .xsmall-up-2 > .column, .xsmall-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }
  .xsmall-up-3 {
    flex-wrap: wrap; }
    .xsmall-up-3 > .column, .xsmall-up-3 > .columns {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .xsmall-up-4 {
    flex-wrap: wrap; }
    .xsmall-up-4 > .column, .xsmall-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }
  .xsmall-up-5 {
    flex-wrap: wrap; }
    .xsmall-up-5 > .column, .xsmall-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }
  .xsmall-up-6 {
    flex-wrap: wrap; }
    .xsmall-up-6 > .column, .xsmall-up-6 > .columns {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .xsmall-up-7 {
    flex-wrap: wrap; }
    .xsmall-up-7 > .column, .xsmall-up-7 > .columns {
      flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .xsmall-up-8 {
    flex-wrap: wrap; }
    .xsmall-up-8 > .column, .xsmall-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media print, screen and (min-width: 30em) and (min-width: 30em) {
  .xsmall-expand {
    flex: 1 1 0px; } }

@media print, screen and (min-width: 30em) {
  .xsmall-flex-dir-row {
    flex-direction: row; }
  .xsmall-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .xsmall-flex-dir-column {
    flex-direction: column; }
  .xsmall-flex-dir-column-reverse {
    flex-direction: column-reverse; }
  .xsmall-flex-child-auto {
    flex: 1 1 auto; }
  .xsmall-flex-child-grow {
    flex: 1 0 auto; }
  .xsmall-flex-child-shrink {
    flex: 0 1 auto; } }

.row.xsmall-unstack > .column, .row.xsmall-unstack > .columns {
  flex: 0 0 100%; }
  @media print, screen and (min-width: 30em) {
    .row.xsmall-unstack > .column, .row.xsmall-unstack > .columns {
      flex: 1 1 0px; } }

@media print, screen and (min-width: 30em) {
  .xsmall-collapse > .column, .xsmall-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .xsmall-uncollapse > .column, .xsmall-uncollapse > .columns {
    padding-right: 0.75rem;
    padding-left: 0.75rem; } }

@media print, screen and (min-width: 40em) {
  .medium-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .medium-offset-0 {
    margin-left: 0%; }
  .medium-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .medium-offset-1 {
    margin-left: 4.16667%; }
  .medium-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .medium-offset-2 {
    margin-left: 8.33333%; }
  .medium-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .medium-offset-3 {
    margin-left: 12.5%; }
  .medium-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .medium-offset-4 {
    margin-left: 16.66667%; }
  .medium-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .medium-offset-5 {
    margin-left: 20.83333%; }
  .medium-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .medium-offset-6 {
    margin-left: 25%; }
  .medium-8, .ui .assets .colors .columns {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .medium-offset-7 {
    margin-left: 29.16667%; }
  .medium-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .medium-offset-8 {
    margin-left: 33.33333%; }
  .medium-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .medium-offset-9 {
    margin-left: 37.5%; }
  .medium-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .medium-offset-10 {
    margin-left: 41.66667%; }
  .medium-12, .skills .consulting, .skills .webdesign, .skills .development, .ui .assets .fonts {
    flex: 0 0 50%;
    max-width: 50%; }
  .medium-offset-11 {
    margin-left: 45.83333%; }
  .medium-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .medium-offset-12 {
    margin-left: 50%; }
  .medium-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .medium-offset-13 {
    margin-left: 54.16667%; }
  .medium-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .medium-offset-14 {
    margin-left: 58.33333%; }
  .medium-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .medium-offset-15 {
    margin-left: 62.5%; }
  .medium-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .medium-offset-16 {
    margin-left: 66.66667%; }
  .medium-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .medium-offset-17 {
    margin-left: 70.83333%; }
  .medium-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .medium-offset-18 {
    margin-left: 75%; }
  .medium-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .medium-offset-19 {
    margin-left: 79.16667%; }
  .medium-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .medium-offset-20 {
    margin-left: 83.33333%; }
  .medium-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .medium-offset-21 {
    margin-left: 87.5%; }
  .medium-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .medium-offset-22 {
    margin-left: 91.66667%; }
  .medium-24, .skills .development {
    flex: 0 0 100%;
    max-width: 100%; }
  .medium-offset-23 {
    margin-left: 95.83333%; }
  .medium-order-1 {
    order: 1; }
  .medium-order-2 {
    order: 2; }
  .medium-order-3 {
    order: 3; }
  .medium-order-4 {
    order: 4; }
  .medium-order-5 {
    order: 5; }
  .medium-order-6 {
    order: 6; }
  .medium-up-1 {
    flex-wrap: wrap; }
    .medium-up-1 > .column, .medium-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }
  .medium-up-2 {
    flex-wrap: wrap; }
    .medium-up-2 > .column, .medium-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }
  .medium-up-3 {
    flex-wrap: wrap; }
    .medium-up-3 > .column, .medium-up-3 > .columns {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .medium-up-4 {
    flex-wrap: wrap; }
    .medium-up-4 > .column, .medium-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }
  .medium-up-5 {
    flex-wrap: wrap; }
    .medium-up-5 > .column, .medium-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }
  .medium-up-6 {
    flex-wrap: wrap; }
    .medium-up-6 > .column, .medium-up-6 > .columns {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .medium-up-7 {
    flex-wrap: wrap; }
    .medium-up-7 > .column, .medium-up-7 > .columns {
      flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .medium-up-8 {
    flex-wrap: wrap; }
    .medium-up-8 > .column, .medium-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media print, screen and (min-width: 40em) and (min-width: 40em) {
  .medium-expand {
    flex: 1 1 0px; } }

@media print, screen and (min-width: 40em) {
  .medium-flex-dir-row {
    flex-direction: row; }
  .medium-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .medium-flex-dir-column {
    flex-direction: column; }
  .medium-flex-dir-column-reverse {
    flex-direction: column-reverse; }
  .medium-flex-child-auto {
    flex: 1 1 auto; }
  .medium-flex-child-grow {
    flex: 1 0 auto; }
  .medium-flex-child-shrink {
    flex: 0 1 auto; } }

.row.medium-unstack > .column, .row.medium-unstack > .columns {
  flex: 0 0 100%; }
  @media print, screen and (min-width: 40em) {
    .row.medium-unstack > .column, .row.medium-unstack > .columns {
      flex: 1 1 0px; } }

@media print, screen and (min-width: 40em) {
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-right: 1rem;
    padding-left: 1rem; } }

@media print, screen and (min-width: 52.5em) {
  .xmedium-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .xmedium-offset-0 {
    margin-left: 0%; }
  .xmedium-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .xmedium-offset-1 {
    margin-left: 4.16667%; }
  .xmedium-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .xmedium-offset-2 {
    margin-left: 8.33333%; }
  .xmedium-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .xmedium-offset-3 {
    margin-left: 12.5%; }
  .xmedium-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .xmedium-offset-4 {
    margin-left: 16.66667%; }
  .xmedium-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .xmedium-offset-5 {
    margin-left: 20.83333%; }
  .xmedium-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .xmedium-offset-6 {
    margin-left: 25%; }
  .xmedium-8, .clients .reviews .avatar, .ui .assets .fonts {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .xmedium-offset-7 {
    margin-left: 29.16667%; }
  .xmedium-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .xmedium-offset-8 {
    margin-left: 33.33333%; }
  .xmedium-10, .cs-profil, .cs-profil .quote-01.active, .cs-profil .quote-02.active, .cs-profil .quote-03.active, .cs-profil .quote-04.active, .cs-profil .quote-end.active, .bs-detail {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .xmedium-offset-9 {
    margin-left: 37.5%; }
  .xmedium-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .xmedium-offset-10 {
    margin-left: 41.66667%; }
  .xmedium-12, .bs-magazine .text, .bs-skills .skill-list ul .columns, .ui .assets .colors, .ui .assets .colors .columns {
    flex: 0 0 50%;
    max-width: 50%; }
  .xmedium-offset-11 {
    margin-left: 45.83333%; }
  .xmedium-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .xmedium-offset-12 {
    margin-left: 50%; }
  .xmedium-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .xmedium-offset-13 {
    margin-left: 54.16667%; }
  .xmedium-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .xmedium-offset-14 {
    margin-left: 58.33333%; }
  .xmedium-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .xmedium-offset-15 {
    margin-left: 62.5%; }
  .xmedium-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .xmedium-offset-16 {
    margin-left: 66.66667%; }
  .xmedium-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .xmedium-offset-17 {
    margin-left: 70.83333%; }
  .xmedium-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .xmedium-offset-18 {
    margin-left: 75%; }
  .xmedium-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .xmedium-offset-19 {
    margin-left: 79.16667%; }
  .xmedium-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .xmedium-offset-20 {
    margin-left: 83.33333%; }
  .xmedium-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .xmedium-offset-21 {
    margin-left: 87.5%; }
  .xmedium-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .xmedium-offset-22 {
    margin-left: 91.66667%; }
  .xmedium-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .xmedium-offset-23 {
    margin-left: 95.83333%; }
  .xmedium-order-1, .cs-profil, .bs-detail {
    order: 1; }
  .xmedium-order-2 {
    order: 2; }
  .xmedium-order-3 {
    order: 3; }
  .xmedium-order-4 {
    order: 4; }
  .xmedium-order-5 {
    order: 5; }
  .xmedium-order-6 {
    order: 6; }
  .xmedium-up-1 {
    flex-wrap: wrap; }
    .xmedium-up-1 > .column, .xmedium-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }
  .xmedium-up-2 {
    flex-wrap: wrap; }
    .xmedium-up-2 > .column, .xmedium-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }
  .xmedium-up-3 {
    flex-wrap: wrap; }
    .xmedium-up-3 > .column, .xmedium-up-3 > .columns {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .xmedium-up-4 {
    flex-wrap: wrap; }
    .xmedium-up-4 > .column, .xmedium-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }
  .xmedium-up-5 {
    flex-wrap: wrap; }
    .xmedium-up-5 > .column, .xmedium-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }
  .xmedium-up-6 {
    flex-wrap: wrap; }
    .xmedium-up-6 > .column, .xmedium-up-6 > .columns {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .xmedium-up-7 {
    flex-wrap: wrap; }
    .xmedium-up-7 > .column, .xmedium-up-7 > .columns {
      flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .xmedium-up-8 {
    flex-wrap: wrap; }
    .xmedium-up-8 > .column, .xmedium-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media print, screen and (min-width: 52.5em) and (min-width: 52.5em) {
  .xmedium-expand {
    flex: 1 1 0px; } }

@media print, screen and (min-width: 52.5em) {
  .xmedium-flex-dir-row {
    flex-direction: row; }
  .xmedium-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .xmedium-flex-dir-column {
    flex-direction: column; }
  .xmedium-flex-dir-column-reverse {
    flex-direction: column-reverse; }
  .xmedium-flex-child-auto {
    flex: 1 1 auto; }
  .xmedium-flex-child-grow {
    flex: 1 0 auto; }
  .xmedium-flex-child-shrink {
    flex: 0 1 auto; } }

.row.xmedium-unstack > .column, .row.xmedium-unstack > .columns {
  flex: 0 0 100%; }
  @media print, screen and (min-width: 52.5em) {
    .row.xmedium-unstack > .column, .row.xmedium-unstack > .columns {
      flex: 1 1 0px; } }

@media print, screen and (min-width: 52.5em) {
  .xmedium-collapse > .column, .xmedium-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .xmedium-uncollapse > .column, .xmedium-uncollapse > .columns {
    padding-right: 1rem;
    padding-left: 1rem; } }

@media print, screen and (min-width: 64em) {
  .large-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .large-offset-0 {
    margin-left: 0%; }
  .large-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .large-offset-1 {
    margin-left: 4.16667%; }
  .large-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .large-offset-2 {
    margin-left: 8.33333%; }
  .large-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .large-offset-3 {
    margin-left: 12.5%; }
  .large-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .large-offset-4 {
    margin-left: 16.66667%; }
  .large-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .large-offset-5 {
    margin-left: 20.83333%; }
  .large-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .large-offset-6 {
    margin-left: 25%; }
  .large-8, .skills .consulting, .skills .webdesign, .skills .development, .ui .assets .colors .columns, .ui .assets .fonts {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .large-offset-7 {
    margin-left: 29.16667%; }
  .large-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .large-offset-8 {
    margin-left: 33.33333%; }
  .large-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .large-offset-9 {
    margin-left: 37.5%; }
  .large-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .large-offset-10 {
    margin-left: 41.66667%; }
  .large-12, .bs-skills .skill-list {
    flex: 0 0 50%;
    max-width: 50%; }
  .large-offset-11 {
    margin-left: 45.83333%; }
  .large-13, .bs-magazine .text, .ui .assets .colors {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .large-offset-12 {
    margin-left: 50%; }
  .large-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .large-offset-13 {
    margin-left: 54.16667%; }
  .large-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .large-offset-14 {
    margin-left: 58.33333%; }
  .large-16, .cs-intro .cs-devices {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .large-offset-15 {
    margin-left: 62.5%; }
  .large-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .large-offset-16 {
    margin-left: 66.66667%; }
  .large-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .large-offset-17 {
    margin-left: 70.83333%; }
  .large-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .large-offset-18 {
    margin-left: 75%; }
  .large-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .large-offset-19 {
    margin-left: 79.16667%; }
  .large-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .large-offset-20 {
    margin-left: 83.33333%; }
  .large-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .large-offset-21 {
    margin-left: 87.5%; }
  .large-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .large-offset-22 {
    margin-left: 91.66667%; }
  .large-24, .bs-skills .skill-list ul .columns {
    flex: 0 0 100%;
    max-width: 100%; }
  .large-offset-23 {
    margin-left: 95.83333%; }
  .large-order-1 {
    order: 1; }
  .large-order-2 {
    order: 2; }
  .large-order-3 {
    order: 3; }
  .large-order-4 {
    order: 4; }
  .large-order-5 {
    order: 5; }
  .large-order-6 {
    order: 6; }
  .large-up-1 {
    flex-wrap: wrap; }
    .large-up-1 > .column, .large-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }
  .large-up-2 {
    flex-wrap: wrap; }
    .large-up-2 > .column, .large-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }
  .large-up-3 {
    flex-wrap: wrap; }
    .large-up-3 > .column, .large-up-3 > .columns {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .large-up-4 {
    flex-wrap: wrap; }
    .large-up-4 > .column, .large-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }
  .large-up-5 {
    flex-wrap: wrap; }
    .large-up-5 > .column, .large-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }
  .large-up-6 {
    flex-wrap: wrap; }
    .large-up-6 > .column, .large-up-6 > .columns {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .large-up-7 {
    flex-wrap: wrap; }
    .large-up-7 > .column, .large-up-7 > .columns {
      flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .large-up-8 {
    flex-wrap: wrap; }
    .large-up-8 > .column, .large-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .large-expand {
    flex: 1 1 0px; } }

@media print, screen and (min-width: 64em) {
  .large-flex-dir-row {
    flex-direction: row; }
  .large-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .large-flex-dir-column {
    flex-direction: column; }
  .large-flex-dir-column-reverse {
    flex-direction: column-reverse; }
  .large-flex-child-auto {
    flex: 1 1 auto; }
  .large-flex-child-grow {
    flex: 1 0 auto; }
  .large-flex-child-shrink {
    flex: 0 1 auto; } }

.row.large-unstack > .column, .row.large-unstack > .columns {
  flex: 0 0 100%; }
  @media print, screen and (min-width: 64em) {
    .row.large-unstack > .column, .row.large-unstack > .columns {
      flex: 1 1 0px; } }

@media print, screen and (min-width: 64em) {
  .large-collapse > .column, .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-right: 1rem;
    padding-left: 1rem; } }

@media screen and (min-width: 75em) {
  .xlarge-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .xlarge-offset-0 {
    margin-left: 0%; }
  .xlarge-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .xlarge-offset-1 {
    margin-left: 4.16667%; }
  .xlarge-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .xlarge-offset-2 {
    margin-left: 8.33333%; }
  .xlarge-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .xlarge-offset-3 {
    margin-left: 12.5%; }
  .xlarge-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .xlarge-offset-4 {
    margin-left: 16.66667%; }
  .xlarge-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .xlarge-offset-5 {
    margin-left: 20.83333%; }
  .xlarge-7, .clients .reviews .avatar {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .xlarge-offset-6 {
    margin-left: 25%; }
  .xlarge-8, .cs-profil, .cs-profil .quote-01.active, .cs-profil .quote-02.active, .cs-profil .quote-03.active, .cs-profil .quote-04.active, .cs-profil .quote-end.active, .bs-detail {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .xlarge-offset-7 {
    margin-left: 29.16667%; }
  .xlarge-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .xlarge-offset-8 {
    margin-left: 33.33333%; }
  .xlarge-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .xlarge-offset-9 {
    margin-left: 37.5%; }
  .xlarge-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .xlarge-offset-10 {
    margin-left: 41.66667%; }
  .xlarge-12, .cs-portfolio-home .cs-otorim, .cs-portfolio-home .cs-citydrive {
    flex: 0 0 50%;
    max-width: 50%; }
  .xlarge-offset-11 {
    margin-left: 45.83333%; }
  .xlarge-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .xlarge-offset-12 {
    margin-left: 50%; }
  .xlarge-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .xlarge-offset-13 {
    margin-left: 54.16667%; }
  .xlarge-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .xlarge-offset-14 {
    margin-left: 58.33333%; }
  .xlarge-16, .cs-intro .cs-devices {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .xlarge-offset-15 {
    margin-left: 62.5%; }
  .xlarge-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .xlarge-offset-16 {
    margin-left: 66.66667%; }
  .xlarge-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .xlarge-offset-17 {
    margin-left: 70.83333%; }
  .xlarge-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .xlarge-offset-18 {
    margin-left: 75%; }
  .xlarge-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .xlarge-offset-19 {
    margin-left: 79.16667%; }
  .xlarge-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .xlarge-offset-20 {
    margin-left: 83.33333%; }
  .xlarge-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .xlarge-offset-21 {
    margin-left: 87.5%; }
  .xlarge-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .xlarge-offset-22 {
    margin-left: 91.66667%; }
  .xlarge-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .xlarge-offset-23 {
    margin-left: 95.83333%; }
  .xlarge-order-1 {
    order: 1; }
  .xlarge-order-2 {
    order: 2; }
  .xlarge-order-3 {
    order: 3; }
  .xlarge-order-4 {
    order: 4; }
  .xlarge-order-5 {
    order: 5; }
  .xlarge-order-6 {
    order: 6; }
  .xlarge-up-1 {
    flex-wrap: wrap; }
    .xlarge-up-1 > .column, .xlarge-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }
  .xlarge-up-2 {
    flex-wrap: wrap; }
    .xlarge-up-2 > .column, .xlarge-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }
  .xlarge-up-3 {
    flex-wrap: wrap; }
    .xlarge-up-3 > .column, .xlarge-up-3 > .columns {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .xlarge-up-4 {
    flex-wrap: wrap; }
    .xlarge-up-4 > .column, .xlarge-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }
  .xlarge-up-5 {
    flex-wrap: wrap; }
    .xlarge-up-5 > .column, .xlarge-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }
  .xlarge-up-6 {
    flex-wrap: wrap; }
    .xlarge-up-6 > .column, .xlarge-up-6 > .columns {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .xlarge-up-7 {
    flex-wrap: wrap; }
    .xlarge-up-7 > .column, .xlarge-up-7 > .columns {
      flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .xlarge-up-8 {
    flex-wrap: wrap; }
    .xlarge-up-8 > .column, .xlarge-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media screen and (min-width: 75em) and (min-width: 75em) {
  .xlarge-expand {
    flex: 1 1 0px; } }

@media screen and (min-width: 75em) {
  .xlarge-flex-dir-row {
    flex-direction: row; }
  .xlarge-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .xlarge-flex-dir-column {
    flex-direction: column; }
  .xlarge-flex-dir-column-reverse {
    flex-direction: column-reverse; }
  .xlarge-flex-child-auto {
    flex: 1 1 auto; }
  .xlarge-flex-child-grow {
    flex: 1 0 auto; }
  .xlarge-flex-child-shrink {
    flex: 0 1 auto; } }

.row.xlarge-unstack > .column, .row.xlarge-unstack > .columns {
  flex: 0 0 100%; }
  @media screen and (min-width: 75em) {
    .row.xlarge-unstack > .column, .row.xlarge-unstack > .columns {
      flex: 1 1 0px; } }

@media screen and (min-width: 75em) {
  .xlarge-collapse > .column, .xlarge-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .xlarge-uncollapse > .column, .xlarge-uncollapse > .columns {
    padding-right: 1rem;
    padding-left: 1rem; } }

@media screen and (min-width: 90em) {
  .xxlarge-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .xxlarge-offset-0 {
    margin-left: 0%; }
  .xxlarge-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .xxlarge-offset-1 {
    margin-left: 4.16667%; }
  .xxlarge-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .xxlarge-offset-2 {
    margin-left: 8.33333%; }
  .xxlarge-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .xxlarge-offset-3 {
    margin-left: 12.5%; }
  .xxlarge-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .xxlarge-offset-4 {
    margin-left: 16.66667%; }
  .xxlarge-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .xxlarge-offset-5 {
    margin-left: 20.83333%; }
  .xxlarge-7, .ui .assets .fonts {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .xxlarge-offset-6 {
    margin-left: 25%; }
  .xxlarge-8, .clients .block-logos .columns, .clients .reviews .avatar {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .xxlarge-offset-7 {
    margin-left: 29.16667%; }
  .xxlarge-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .xxlarge-offset-8 {
    margin-left: 33.33333%; }
  .xxlarge-10, .clients .block-logos {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .xxlarge-offset-9 {
    margin-left: 37.5%; }
  .xxlarge-11, .ui .assets .colors {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .xxlarge-offset-10 {
    margin-left: 41.66667%; }
  .xxlarge-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .xxlarge-offset-11 {
    margin-left: 45.83333%; }
  .xxlarge-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .xxlarge-offset-12 {
    margin-left: 50%; }
  .xxlarge-14, .clients .reviews {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .xxlarge-offset-13 {
    margin-left: 54.16667%; }
  .xxlarge-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .xxlarge-offset-14 {
    margin-left: 58.33333%; }
  .xxlarge-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .xxlarge-offset-15 {
    margin-left: 62.5%; }
  .xxlarge-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .xxlarge-offset-16 {
    margin-left: 66.66667%; }
  .xxlarge-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .xxlarge-offset-17 {
    margin-left: 70.83333%; }
  .xxlarge-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .xxlarge-offset-18 {
    margin-left: 75%; }
  .xxlarge-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .xxlarge-offset-19 {
    margin-left: 79.16667%; }
  .xxlarge-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .xxlarge-offset-20 {
    margin-left: 83.33333%; }
  .xxlarge-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .xxlarge-offset-21 {
    margin-left: 87.5%; }
  .xxlarge-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .xxlarge-offset-22 {
    margin-left: 91.66667%; }
  .xxlarge-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .xxlarge-offset-23 {
    margin-left: 95.83333%; }
  .xxlarge-order-1 {
    order: 1; }
  .xxlarge-order-2 {
    order: 2; }
  .xxlarge-order-3 {
    order: 3; }
  .xxlarge-order-4 {
    order: 4; }
  .xxlarge-order-5 {
    order: 5; }
  .xxlarge-order-6 {
    order: 6; }
  .xxlarge-up-1 {
    flex-wrap: wrap; }
    .xxlarge-up-1 > .column, .xxlarge-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }
  .xxlarge-up-2 {
    flex-wrap: wrap; }
    .xxlarge-up-2 > .column, .xxlarge-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }
  .xxlarge-up-3 {
    flex-wrap: wrap; }
    .xxlarge-up-3 > .column, .xxlarge-up-3 > .columns {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .xxlarge-up-4 {
    flex-wrap: wrap; }
    .xxlarge-up-4 > .column, .xxlarge-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }
  .xxlarge-up-5 {
    flex-wrap: wrap; }
    .xxlarge-up-5 > .column, .xxlarge-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }
  .xxlarge-up-6 {
    flex-wrap: wrap; }
    .xxlarge-up-6 > .column, .xxlarge-up-6 > .columns {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .xxlarge-up-7 {
    flex-wrap: wrap; }
    .xxlarge-up-7 > .column, .xxlarge-up-7 > .columns {
      flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .xxlarge-up-8 {
    flex-wrap: wrap; }
    .xxlarge-up-8 > .column, .xxlarge-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media screen and (min-width: 90em) and (min-width: 90em) {
  .xxlarge-expand {
    flex: 1 1 0px; } }

@media screen and (min-width: 90em) {
  .xxlarge-flex-dir-row {
    flex-direction: row; }
  .xxlarge-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .xxlarge-flex-dir-column {
    flex-direction: column; }
  .xxlarge-flex-dir-column-reverse {
    flex-direction: column-reverse; }
  .xxlarge-flex-child-auto {
    flex: 1 1 auto; }
  .xxlarge-flex-child-grow {
    flex: 1 0 auto; }
  .xxlarge-flex-child-shrink {
    flex: 0 1 auto; } }

.row.xxlarge-unstack > .column, .row.xxlarge-unstack > .columns {
  flex: 0 0 100%; }
  @media screen and (min-width: 90em) {
    .row.xxlarge-unstack > .column, .row.xxlarge-unstack > .columns {
      flex: 1 1 0px; } }

@media screen and (min-width: 90em) {
  .xxlarge-collapse > .column, .xxlarge-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .xxlarge-uncollapse > .column, .xxlarge-uncollapse > .columns {
    padding-right: 1rem;
    padding-left: 1rem; } }

@media screen and (min-width: 100em) {
  .xxxlarge-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .xxxlarge-offset-0 {
    margin-left: 0%; }
  .xxxlarge-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .xxxlarge-offset-1 {
    margin-left: 4.16667%; }
  .xxxlarge-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .xxxlarge-offset-2, .cs-intro .cs-devices {
    margin-left: 8.33333%; }
  .xxxlarge-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .xxxlarge-offset-3 {
    margin-left: 12.5%; }
  .xxxlarge-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .xxxlarge-offset-4 {
    margin-left: 16.66667%; }
  .xxxlarge-6, .cs-intro .cs-text, .bs-detail {
    flex: 0 0 25%;
    max-width: 25%; }
  .xxxlarge-offset-5 {
    margin-left: 20.83333%; }
  .xxxlarge-7, .cs-profil, .cs-profil .quote-01.active, .cs-profil .quote-02.active, .cs-profil .quote-03.active, .cs-profil .quote-04.active, .cs-profil .quote-end.active {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .xxxlarge-offset-6 {
    margin-left: 25%; }
  .xxxlarge-8, .clients .reviews .avatar {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .xxxlarge-offset-7 {
    margin-left: 29.16667%; }
  .xxxlarge-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .xxxlarge-offset-8 {
    margin-left: 33.33333%; }
  .xxxlarge-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .xxxlarge-offset-9 {
    margin-left: 37.5%; }
  .xxxlarge-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .xxxlarge-offset-10 {
    margin-left: 41.66667%; }
  .xxxlarge-12, .clients .block-logos, .clients .reviews {
    flex: 0 0 50%;
    max-width: 50%; }
  .xxxlarge-offset-11 {
    margin-left: 45.83333%; }
  .xxxlarge-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .xxxlarge-offset-12 {
    margin-left: 50%; }
  .xxxlarge-14, .cs-intro .cs-devices {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .xxxlarge-offset-13 {
    margin-left: 54.16667%; }
  .xxxlarge-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .xxxlarge-offset-14 {
    margin-left: 58.33333%; }
  .xxxlarge-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .xxxlarge-offset-15 {
    margin-left: 62.5%; }
  .xxxlarge-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .xxxlarge-offset-16 {
    margin-left: 66.66667%; }
  .xxxlarge-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .xxxlarge-offset-17 {
    margin-left: 70.83333%; }
  .xxxlarge-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .xxxlarge-offset-18 {
    margin-left: 75%; }
  .xxxlarge-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .xxxlarge-offset-19 {
    margin-left: 79.16667%; }
  .xxxlarge-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .xxxlarge-offset-20 {
    margin-left: 83.33333%; }
  .xxxlarge-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .xxxlarge-offset-21 {
    margin-left: 87.5%; }
  .xxxlarge-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .xxxlarge-offset-22 {
    margin-left: 91.66667%; }
  .xxxlarge-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .xxxlarge-offset-23 {
    margin-left: 95.83333%; }
  .xxxlarge-order-1 {
    order: 1; }
  .xxxlarge-order-2 {
    order: 2; }
  .xxxlarge-order-3 {
    order: 3; }
  .xxxlarge-order-4 {
    order: 4; }
  .xxxlarge-order-5 {
    order: 5; }
  .xxxlarge-order-6 {
    order: 6; }
  .xxxlarge-up-1 {
    flex-wrap: wrap; }
    .xxxlarge-up-1 > .column, .xxxlarge-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }
  .xxxlarge-up-2 {
    flex-wrap: wrap; }
    .xxxlarge-up-2 > .column, .xxxlarge-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }
  .xxxlarge-up-3 {
    flex-wrap: wrap; }
    .xxxlarge-up-3 > .column, .xxxlarge-up-3 > .columns {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .xxxlarge-up-4 {
    flex-wrap: wrap; }
    .xxxlarge-up-4 > .column, .xxxlarge-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }
  .xxxlarge-up-5 {
    flex-wrap: wrap; }
    .xxxlarge-up-5 > .column, .xxxlarge-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }
  .xxxlarge-up-6 {
    flex-wrap: wrap; }
    .xxxlarge-up-6 > .column, .xxxlarge-up-6 > .columns {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .xxxlarge-up-7 {
    flex-wrap: wrap; }
    .xxxlarge-up-7 > .column, .xxxlarge-up-7 > .columns {
      flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .xxxlarge-up-8 {
    flex-wrap: wrap; }
    .xxxlarge-up-8 > .column, .xxxlarge-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media screen and (min-width: 100em) and (min-width: 100em) {
  .xxxlarge-expand {
    flex: 1 1 0px; } }

@media screen and (min-width: 100em) {
  .xxxlarge-flex-dir-row {
    flex-direction: row; }
  .xxxlarge-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .xxxlarge-flex-dir-column {
    flex-direction: column; }
  .xxxlarge-flex-dir-column-reverse {
    flex-direction: column-reverse; }
  .xxxlarge-flex-child-auto {
    flex: 1 1 auto; }
  .xxxlarge-flex-child-grow {
    flex: 1 0 auto; }
  .xxxlarge-flex-child-shrink {
    flex: 0 1 auto; } }

.row.xxxlarge-unstack > .column, .row.xxxlarge-unstack > .columns {
  flex: 0 0 100%; }
  @media screen and (min-width: 100em) {
    .row.xxxlarge-unstack > .column, .row.xxxlarge-unstack > .columns {
      flex: 1 1 0px; } }

@media screen and (min-width: 100em) {
  .xxxlarge-collapse > .column, .xxxlarge-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .xxxlarge-uncollapse > .column, .xxxlarge-uncollapse > .columns {
    padding-right: 1rem;
    padding-left: 1rem; } }

.shrink {
  flex: 0 0 auto;
  max-width: 100%; }

.hide {
  display: none !important; }

.invisible {
  visibility: hidden; }

@media screen and (max-width: 29.9375em) {
  .hide-for-small-only, .cs-profil .cs-presentation div p.paragraph2, .ui .wallpaper .laptop {
    display: none !important; } }

@media screen and (max-width: 0em), screen and (min-width: 30em) {
  .show-for-small-only {
    display: none !important; } }

@media print, screen and (min-width: 30em) {
  .hide-for-xsmall, .cs-menu-modal .cs-social-link {
    display: none !important; } }

@media screen and (max-width: 29.9375em) {
  .show-for-xsmall, .clients .block-logos .columns.hsbc {
    display: none !important; } }

@media screen and (min-width: 30em) and (max-width: 39.9375em) {
  .hide-for-xsmall-only {
    display: none !important; } }

@media screen and (max-width: 29.9375em), screen and (min-width: 40em) {
  .show-for-xsmall-only {
    display: none !important; } }

@media print, screen and (min-width: 40em) {
  .hide-for-medium, .cs-intro .cs-devices-mobile {
    display: none !important; } }

@media screen and (max-width: 39.9375em) {
  .show-for-medium, .cs-intro .cs-devices, .clients .block-logos .columns.fox, .bs-masonry .grid-item.w2, .bs-masonry .grid-item.w3 {
    display: none !important; } }

@media screen and (min-width: 40em) and (max-width: 52.4375em) {
  .hide-for-medium-only {
    display: none !important; } }

@media screen and (max-width: 39.9375em), screen and (min-width: 52.5em) {
  .show-for-medium-only {
    display: none !important; } }

@media print, screen and (min-width: 52.5em) {
  .hide-for-xmedium {
    display: none !important; } }

@media screen and (max-width: 52.4375em) {
  .show-for-xmedium {
    display: none !important; } }

@media screen and (min-width: 52.5em) and (max-width: 63.9375em) {
  .hide-for-xmedium-only {
    display: none !important; } }

@media screen and (max-width: 52.4375em), screen and (min-width: 64em) {
  .show-for-xmedium-only {
    display: none !important; } }

@media print, screen and (min-width: 64em) {
  .hide-for-large {
    display: none !important; } }

@media screen and (max-width: 63.9375em) {
  .show-for-large, .plane-animation .cloud2, .plane-animation .cloud3 {
    display: none !important; } }

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .hide-for-large-only {
    display: none !important; } }

@media screen and (max-width: 63.9375em), screen and (min-width: 75em) {
  .show-for-large-only {
    display: none !important; } }

@media screen and (min-width: 75em) {
  .hide-for-xlarge {
    display: none !important; } }

@media screen and (max-width: 74.9375em) {
  .show-for-xlarge {
    display: none !important; } }

@media screen and (min-width: 75em) and (max-width: 89.9375em) {
  .hide-for-xlarge-only {
    display: none !important; } }

@media screen and (max-width: 74.9375em), screen and (min-width: 90em) {
  .show-for-xlarge-only {
    display: none !important; } }

@media screen and (min-width: 90em) {
  .hide-for-xxlarge {
    display: none !important; } }

@media screen and (max-width: 89.9375em) {
  .show-for-xxlarge {
    display: none !important; } }

@media screen and (min-width: 90em) and (max-width: 99.9375em) {
  .hide-for-xxlarge-only {
    display: none !important; } }

@media screen and (max-width: 89.9375em), screen and (min-width: 100em) {
  .show-for-xxlarge-only {
    display: none !important; } }

@media screen and (min-width: 100em) {
  .hide-for-xxxlarge {
    display: none !important; } }

@media screen and (max-width: 99.9375em) {
  .show-for-xxxlarge {
    display: none !important; } }

@media screen and (min-width: 100em) {
  .hide-for-xxxlarge-only {
    display: none !important; } }

@media screen and (max-width: 99.9375em) {
  .show-for-xxxlarge-only {
    display: none !important; } }

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0); }

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto; }

.show-for-landscape,
.hide-for-portrait {
  display: block !important; }
  @media screen and (orientation: landscape) {
    .show-for-landscape,
    .hide-for-portrait {
      display: block !important; } }
  @media screen and (orientation: portrait) {
    .show-for-landscape,
    .hide-for-portrait {
      display: none !important; } }

.hide-for-landscape,
.show-for-portrait {
  display: none !important; }
  @media screen and (orientation: landscape) {
    .hide-for-landscape,
    .show-for-portrait {
      display: none !important; } }
  @media screen and (orientation: portrait) {
    .hide-for-landscape,
    .show-for-portrait {
      display: block !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto; }

.clearfix::before, .clearfix::after {
  display: table;
  content: ' '; }

.clearfix::after {
  clear: both; }

.align-right {
  justify-content: flex-end; }

.align-center, .cs-profil .quote-01, .cs-profil .quote-02, .cs-profil .quote-03, .cs-profil .quote-04, .cs-profil .quote-end, .ui .assets {
  justify-content: center; }

.align-justify {
  justify-content: space-between; }

.align-spaced, .ui .assets .colors .row {
  justify-content: space-around; }

.align-top {
  align-items: flex-start; }

.align-self-top {
  align-self: flex-start; }

.align-bottom {
  align-items: flex-end; }

.align-self-bottom {
  align-self: flex-end; }

.align-middle, .bs-wireframe {
  align-items: center; }

.align-self-middle, .cs-profil .quote-01.active .quote, .cs-profil .quote-02.active .quote, .cs-profil .quote-03.active .quote, .cs-profil .quote-04.active .quote, .cs-profil .quote-end.active .quote, .cs-intro .cs-text, .cs-portfolio-home .cs-boostravel > .row .columns, .cs-portfolio-home .cs-otorim > .row .columns, .cs-portfolio-home .cs-citydrive > .row .columns, footer .row .columns {
  align-self: center; }

.align-stretch {
  align-items: stretch; }

.align-self-stretch, .bs-visual {
  align-self: stretch; }

.small-order-1, .cs-slide, .bs-visual {
  order: 1; }

.small-order-2, .cs-profil, .bs-detail {
  order: 2; }

.small-order-3 {
  order: 3; }

.small-order-4 {
  order: 4; }

.small-order-5 {
  order: 5; }

.small-order-6 {
  order: 6; }

@media print, screen and (min-width: 30em) {
  .xsmall-order-1 {
    order: 1; }
  .xsmall-order-2 {
    order: 2; }
  .xsmall-order-3 {
    order: 3; }
  .xsmall-order-4 {
    order: 4; }
  .xsmall-order-5 {
    order: 5; }
  .xsmall-order-6 {
    order: 6; } }

@media print, screen and (min-width: 40em) {
  .medium-order-1 {
    order: 1; }
  .medium-order-2 {
    order: 2; }
  .medium-order-3 {
    order: 3; }
  .medium-order-4 {
    order: 4; }
  .medium-order-5 {
    order: 5; }
  .medium-order-6 {
    order: 6; } }

@media print, screen and (min-width: 52.5em) {
  .xmedium-order-1, .cs-profil, .bs-detail {
    order: 1; }
  .xmedium-order-2 {
    order: 2; }
  .xmedium-order-3 {
    order: 3; }
  .xmedium-order-4 {
    order: 4; }
  .xmedium-order-5 {
    order: 5; }
  .xmedium-order-6 {
    order: 6; } }

@media print, screen and (min-width: 64em) {
  .large-order-1 {
    order: 1; }
  .large-order-2 {
    order: 2; }
  .large-order-3 {
    order: 3; }
  .large-order-4 {
    order: 4; }
  .large-order-5 {
    order: 5; }
  .large-order-6 {
    order: 6; } }

@media screen and (min-width: 75em) {
  .xlarge-order-1 {
    order: 1; }
  .xlarge-order-2 {
    order: 2; }
  .xlarge-order-3 {
    order: 3; }
  .xlarge-order-4 {
    order: 4; }
  .xlarge-order-5 {
    order: 5; }
  .xlarge-order-6 {
    order: 6; } }

@media screen and (min-width: 90em) {
  .xxlarge-order-1 {
    order: 1; }
  .xxlarge-order-2 {
    order: 2; }
  .xxlarge-order-3 {
    order: 3; }
  .xxlarge-order-4 {
    order: 4; }
  .xxlarge-order-5 {
    order: 5; }
  .xxlarge-order-6 {
    order: 6; } }

@media screen and (min-width: 100em) {
  .xxxlarge-order-1 {
    order: 1; }
  .xxxlarge-order-2 {
    order: 2; }
  .xxxlarge-order-3 {
    order: 3; }
  .xxxlarge-order-4 {
    order: 4; }
  .xxxlarge-order-5 {
    order: 5; }
  .xxxlarge-order-6 {
    order: 6; } }

/*
==================================================
====================== Mixin & Variables            			
==================================================
*/
.g-reverse, .cs-contact-modal .cs-tel:after, .cs-menu-modal .cs-tel:after, .cs-contact-modal .cs-mail:after, .cs-menu-modal .cs-mail:after {
  unicode-bidi: bidi-override;
  direction: rtl; }

/*
==================================================
====================== Global            			
==================================================
*/
body {
  background: #ddd;
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  color: #282D33;
  font-size: 1.1rem; }
  body .cs-dark, body .cs-dark2 {
    position: fixed;
    z-index: 200;
    width: 100vw;
    height: 100vh;
    top: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    opacity: 0;
    display: none;
    visibility: hidden; }
    body .cs-dark.active, body .cs-dark2.active {
      opacity: 0;
      animation-name: fade-in;
      animation-duration: 0.5s;
      animation-delay: 0s;
      animation-fill-mode: forwards;
      animation-timing-function: cubic-bezier(0.1, 0.89, 0.64, 0.99);
      display: block;
      visibility: visible; }
    body .cs-dark .cs-dark2, body .cs-dark2 .cs-dark2 {
      z-index: 99; }
  body > .cs-container {
    padding: 5%;
    padding-top: 0; }
    @media screen and (max-width: 74.9375em) {
      body > .cs-container {
        padding: 80px;
        padding-top: 0; } }
    @media screen and (max-width: 63.9375em) {
      body > .cs-container {
        padding: 6%;
        padding-top: 0; } }

.block {
  height: 50px;
  width: 50px;
  background: #000; }

.hidden {
  display: none; }

h1, .cs-contact-modal h3, .cs-menu-modal h3, h2, h3 {
  font-family: 'Montserrat', 'Titillium Web', arial;
  text-transform: uppercase;
  padding: 0;
  margin: 0; }

a {
  text-decoration: none;
  color: #63EDC5; }

/*
==================================================
====================== Modal Contact            			
==================================================
*/
.cs-contact-modal, .cs-menu-modal {
  opacity: 0;
  visibility: hidden;
  display: none;
  position: fixed;
  width: 50vw;
  top: 35vh;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 680px;
  background: #fff;
  z-index: 250;
  padding: 2.5rem 3rem;
  border-bottom: 5px solid #3DC39C;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.5);
  color: #999; }
  .cs-contact-modal h3, .cs-menu-modal h3 {
    color: #3DC39C; }
  .cs-contact-modal a.cs-close, .cs-menu-modal a.cs-close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1rem 1.2rem;
    box-shadow: 0 1px 0 0 #fff inset;
    transition: all ease 0.3s; }
    .cs-contact-modal a.cs-close .icon-ico-plus, .cs-menu-modal a.cs-close .icon-ico-plus {
      color: #b2b2b2;
      display: inline-block;
      transform: rotate(45deg); }
    .cs-contact-modal a.cs-close:hover, .cs-menu-modal a.cs-close:hover {
      box-shadow: 0 80px 0 0 #3DC39C inset; }
      .cs-contact-modal a.cs-close:hover .icon-ico-plus, .cs-menu-modal a.cs-close:hover .icon-ico-plus {
        color: #fff; }
  .cs-contact-modal .cs-tel, .cs-menu-modal .cs-tel {
    display: inline-block;
    color: #282D33;
    font-size: 1.2rem; }
    .cs-contact-modal .cs-tel:after, .cs-menu-modal .cs-tel:after {
      content: "66.38.01.41.60"; }
  .cs-contact-modal .cs-mail, .cs-menu-modal .cs-mail {
    color: #3DC39C;
    display: inline-block;
    font-size: 1.2rem; }
    .cs-contact-modal .cs-mail:after, .cs-menu-modal .cs-mail:after {
      content: "rf.hcuarts\40liryc"; }
  @media screen and (max-width: 74.9375em) {
    .cs-contact-modal, .cs-menu-modal {
      top: 30vh;
      width: 60vw;
      padding: 2.5rem; } }
  @media screen and (max-width: 52.4375em) {
    .cs-contact-modal, .cs-menu-modal {
      top: 25vh;
      width: 75vw;
      padding: 2rem; } }
  @media screen and (max-width: 29.9375em) {
    .cs-contact-modal, .cs-menu-modal {
      top: 20vh;
      width: 85vw;
      padding: 2rem; } }
  .cs-contact-modal.active, .cs-menu-modal.active {
    display: inline-block;
    opacity: 0;
    animation-name: contact-modal;
    animation-duration: 0.5s;
    animation-delay: 0s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.1, 0.89, 0.64, 0.99); }
  .cs-contact-modal.inactive, .cs-menu-modal.inactive {
    display: inline-block;
    opacity: 0;
    animation-name: contact-modal-off;
    animation-duration: 0.5s;
    animation-delay: 0s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.1, 0.89, 0.64, 0.99); }

.cs-menu-modal {
  max-width: 400px;
  padding: 2rem;
  top: 20vh; }
  .cs-menu-modal nav, .cs-menu-modal .cs-social-link {
    padding: 0 1rem;
    background: #fff;
    text-transform: uppercase;
    text-align: center; }
    .cs-menu-modal nav ul, .cs-menu-modal .cs-social-link ul {
      padding: 0;
      margin: 0; }
    .cs-menu-modal nav li, .cs-menu-modal .cs-social-link li {
      list-style: none;
      display: block; }
    .cs-menu-modal nav a, .cs-menu-modal .cs-social-link a {
      padding: 1rem;
      font-size: 1.4rem;
      font-weight: 600;
      color: #666;
      display: inline-block; }
      .cs-menu-modal nav a:hover, .cs-menu-modal .cs-social-link a:hover {
        color: #3DC39C; }
  .cs-menu-modal .cs-social-link {
    margin-top: 1rem; }
    .cs-menu-modal .cs-social-link li {
      display: inline-block; }

/*
==================================================
====================== Logo          			
==================================================
*/
.cs-logo {
  padding-left: 0;
  opacity: 0;
  animation-name: fade-from-top;
  animation-duration: 0.5s;
  animation-delay: 3.8s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.1, 0.89, 0.64, 0.99); }
  .cs-logo .cs-flag {
    height: 80px;
    padding: 17px 20px;
    background: #111;
    background: -moz-linear-gradient(top, #111 0%, #333 100%);
    background: -webkit-linear-gradient(top, #111 0%, #333 100%);
    background: linear-gradient(to bottom, #111 0%, #333 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color1', endColorstr='$color2',GradientType=0 );
    color: #63EDC5;
    display: inline-block;
    font-size: 1.8rem; }
    @media screen and (max-width: 29.9375em) {
      .cs-logo .cs-flag {
        height: 60px;
        padding: 13px 16px;
        background: #111;
        background: -moz-linear-gradient(top, #111 0%, #333 100%);
        background: -webkit-linear-gradient(top, #111 0%, #333 100%);
        background: linear-gradient(to bottom, #111 0%, #333 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color1', endColorstr='$color2',GradientType=0 );
        color: #63EDC5;
        display: inline-block;
        font-size: 1.4rem; } }

/*
==================================================
====================== Top bar            			
==================================================
*/
.cs-top-bar {
  height: 80px;
  margin-bottom: 40px; }
  @media screen and (max-width: 29.9375em) {
    .cs-top-bar {
      margin-bottom: 0; } }
  .cs-top-bar .cs-social-link {
    text-align: right;
    padding-right: 0; }
    .cs-top-bar .cs-social-link ul {
      margin: 0; }
    .cs-top-bar .cs-social-link li {
      list-style: none;
      display: inline-block;
      text-transform: uppercase;
      font-weight: 600; }
      .cs-top-bar .cs-social-link li a {
        font-size: 1.5rem;
        display: inline-block;
        padding: 20px;
        color: #b2b2b2;
        transition: all ease 0.3s;
        text-shadow: 0 1px 0 #fff; }
        .cs-top-bar .cs-social-link li a:hover {
          color: #3DC39C;
          text-shadow: 0 1px 20px #fff; }
        @media screen and (max-width: 52.4375em) {
          .cs-top-bar .cs-social-link li a {
            font-size: 1.3rem;
            padding: 20px 1rem; } }
        @media screen and (max-width: 29.9375em) {
          .cs-top-bar .cs-social-link li a:not(.cs-burger) {
            display: none; } }
      .cs-top-bar .cs-social-link li a.cs-burger {
        font-size: 1.5rem;
        background: white;
        color: #3DC39C;
        margin-left: 10px;
        box-shadow: 0 0 0 0 #fff inset;
        text-shadow: none;
        height: 80px; }
        .cs-top-bar .cs-social-link li a.cs-burger:hover {
          box-shadow: 0 -80px 0 0 #3DC39C inset;
          color: #fff;
          text-shadow: none; }
        @media screen and (max-width: 29.9375em) {
          .cs-top-bar .cs-social-link li a.cs-burger {
            height: 60px;
            padding: 13px 1rem;
            background: transparent;
            color: #b2b2b2; } }
      .cs-top-bar .cs-social-link li:nth-child(1) {
        opacity: 0;
        animation-name: fade-from-top;
        animation-duration: 0.5s;
        animation-delay: 4.2s;
        animation-fill-mode: forwards;
        animation-timing-function: cubic-bezier(0.1, 0.89, 0.64, 0.99);
        margin-left: 0; }
      .cs-top-bar .cs-social-link li:nth-child(2) {
        opacity: 0;
        animation-name: fade-from-top;
        animation-duration: 0.5s;
        animation-delay: 4s;
        animation-fill-mode: forwards;
        animation-timing-function: cubic-bezier(0.1, 0.89, 0.64, 0.99); }
      .cs-top-bar .cs-social-link li:nth-child(3) {
        opacity: 0;
        animation-name: fade-from-top;
        animation-duration: 0.5s;
        animation-delay: 3.8s;
        animation-fill-mode: forwards;
        animation-timing-function: cubic-bezier(0.1, 0.89, 0.64, 0.99); }
      .cs-top-bar .cs-social-link li:nth-child(4) {
        opacity: 0;
        animation-name: fade-from-top;
        animation-duration: 0.5s;
        animation-delay: 3.6s;
        animation-fill-mode: forwards;
        animation-timing-function: cubic-bezier(0.1, 0.89, 0.64, 0.99); }

/*
==================================================
====================== Header / Slider     			
==================================================
*/
header {
  position: relative;
  overflow: hidden; }

@media screen and (min-width: 100em) {
  .reveral-header {
    position: absolute;
    animation-name: header-xlarge;
    height: 100%;
    background: #282D33;
    z-index: 150;
    animation-duration: 1s;
    animation-delay: 2.8s;
    animation-direction: normal;
    animation-timing-function: ease-in-out; } }

@media screen and (max-width: 99.9375em) {
  .reveral-header {
    position: absolute;
    animation-name: header-large;
    height: 100%;
    background: #282D33;
    z-index: 150;
    animation-duration: 1s;
    animation-delay: 2.8s;
    animation-direction: normal;
    animation-timing-function: ease-in-out; } }

@media screen and (max-width: 74.9375em) {
  .reveral-header {
    position: absolute;
    animation-name: header-small;
    height: 100%;
    background: #282D33;
    z-index: 150;
    animation-duration: 1s;
    animation-delay: 2.8s;
    animation-direction: normal;
    animation-timing-function: ease-in-out; } }

@media screen and (max-width: 52.4375em) {
  .reveral-header {
    position: absolute;
    animation-name: header-small;
    height: 100%;
    background: #282D33;
    z-index: 150;
    animation-duration: 1s;
    animation-delay: 2.8s;
    animation-direction: normal;
    animation-timing-function: ease-in-out; } }

.cs-profil {
  color: #fff;
  background: #282D33;
  opacity: 0;
  animation-name: fade-in;
  animation-duration: 0;
  animation-delay: 3.4s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.1, 0.89, 0.64, 0.99); }
  .cs-profil .cs-presentation {
    padding: 0 1.5em 1.5em 1.5em;
    position: relative; }
    .cs-profil .cs-presentation img.photo {
      margin-left: calc(-15px - 1.5em);
      max-width: calc(100% + 30px + 3em);
      display: block;
      opacity: 0;
      animation-name: fade-in;
      animation-duration: 6s;
      animation-delay: 4s;
      animation-fill-mode: forwards;
      animation-timing-function: cubic-bezier(0.1, 0.89, 0.64, 0.99); }
      @media screen and (min-width: 40em) and (max-width: 52.4375em) {
        .cs-profil .cs-presentation img.photo {
          width: 350px;
          position: absolute;
          top: 0;
          left: 0; } }
    @media screen and (min-width: 30em) and (max-width: 39.9375em) {
      .cs-profil .cs-presentation {
        text-align: right; }
        .cs-profil .cs-presentation div {
          text-align: left; }
        .cs-profil .cs-presentation img.photo {
          transform: scaleX(-1);
          width: 400px;
          display: inline;
          margin-right: calc(-15px - 1.5em); } }
    @media screen and (min-width: 40em) and (max-width: 52.4375em) {
      .cs-profil .cs-presentation div {
        display: inline-block;
        max-width: 70%;
        margin-left: 32%; } }
    .cs-profil .cs-presentation div h2 {
      color: rgba(255, 255, 255, 0.15);
      font-size: 2rem;
      margin-top: -140px;
      opacity: 0;
      animation-name: fade-from-top;
      animation-duration: 2s;
      animation-delay: 4s;
      animation-fill-mode: forwards;
      animation-timing-function: cubic-bezier(0.1, 0.89, 0.64, 0.99); }
      @media screen and (min-width: 40em) and (max-width: 52.4375em) {
        .cs-profil .cs-presentation div h2 {
          margin-top: 0.7em; } }
      @media screen and (max-width: 39.9375em) {
        .cs-profil .cs-presentation div h2 {
          margin-top: -140px; } }
      @media screen and (max-width: 29.9375em) {
        .cs-profil .cs-presentation div h2 {
          margin-top: -100px; } }
    .cs-profil .cs-presentation div p {
      font-size: 1rem;
      opacity: 0;
      animation-name: fade-from-bottom;
      animation-duration: 2s;
      animation-delay: 4s;
      animation-fill-mode: forwards;
      animation-timing-function: cubic-bezier(0.1, 0.89, 0.64, 0.99); }
      @media (max-height: 600px) and (max-width: 1400px) {
        .cs-profil .cs-presentation div p.paragraph2 {
          display: none; } }
      @media screen and (max-width: 29.9375em) {
        .cs-profil .cs-presentation div p {
          font-size: 1rem; } }
  .cs-profil a.cs-more {
    font-size: 1.1rem;
    padding: 8px 16px;
    background: #111;
    display: inline-block;
    margin: 0.8em 1px;
    transition: all ease 0.3s;
    opacity: 0;
    animation-name: fade-in;
    animation-duration: 2s;
    animation-delay: 5s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.1, 0.89, 0.64, 0.99); }
    @media screen and (min-width: 40em) and (max-width: 52.4375em) {
      .cs-profil a.cs-more {
        position: absolute;
        bottom: 1rem;
        left: 5%; } }
    .cs-profil a.cs-more:hover {
      box-shadow: 0 0 0 1px #fff;
      background: #3DC39C;
      color: #fff; }
  .cs-profil a.cs-more2, .cs-profil a.cs-more2, .cs-profil a.cs-more3, .cs-profil a.cs-close {
    font-size: 1.1rem;
    display: inline-block;
    background: rgba(0, 0, 0, 0.2);
    padding: 8px 16px;
    margin-top: 1.5rem;
    transition: all ease 0.3s; }
    .cs-profil a.cs-more2:hover, .cs-profil a.cs-more2:hover, .cs-profil a.cs-more3:hover, .cs-profil a.cs-close:hover {
      background: #fff;
      color: #3DC39C; }
  .cs-profil .reveral-quote {
    display: none;
    position: absolute;
    animation-name: slide-h;
    height: 100%;
    background: #111;
    z-index: 300;
    animation-duration: 1s;
    animation-delay: 0s;
    animation-direction: normal;
    animation-timing-function: ease-in-out; }
    @media screen and (max-width: 63.9375em) {
      .cs-profil .reveral-quote {
        padding-top: 0; } }
  .cs-profil .quote {
    display: none;
    text-align: center;
    color: #fff;
    font-size: 1.8rem; }
    @media screen and (max-width: 39.9375em) {
      .cs-profil .quote {
        font-size: 1.5rem; } }
    .cs-profil .quote svg {
      vertical-align: top; }
    .cs-profil .quote svg.cyril {
      width: 150px; }
      .cs-profil .quote svg.cyril .sparkle {
        animation-name: sparkle;
        animation-duration: 0.1s;
        animation-timing-function: ease-in-out;
        animation-delay: 0;
        animation-fill-mode: forwards;
        animation-direction: alternate;
        animation-iteration-count: infinite; }

@keyframes sparkle {
  0% {
    transform: translateX(0) scale(1); }
  100% {
    transform: translateX(1px) scale(1.01); } }
      .cs-profil .quote svg.cyril .sparkle-min {
        animation-name: sparkle-min;
        animation-duration: 0.1s;
        animation-timing-function: ease-in-out;
        animation-delay: 0;
        animation-fill-mode: forwards;
        animation-direction: alternate;
        animation-iteration-count: infinite; }

@keyframes sparkle-min {
  0% {
    transform: translateY(-2px); }
  100% {
    transform: translateY(0); } }
    .cs-profil .quote svg.highlander {
      width: 170px; }
      .cs-profil .quote svg.highlander .light {
        opacity: 0;
        animation-name: light;
        animation-duration: 3s;
        animation-timing-function: ease-in-out;
        animation-delay: 0s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        transform-origin: 30px 24px; }

@keyframes light {
  0% {
    opacity: 0;
    transform: rotate(0deg); }
  15% {
    opacity: 1; }
  30%, 100% {
    opacity: 0;
    transform: rotate(360deg); } }
    .cs-profil .quote svg.monitor {
      width: 170px; }
      .cs-profil .quote svg.monitor #yellow {
        animation-name: yellow;
        animation-duration: 5s;
        animation-timing-function: ease-in-out;
        animation-delay: 0s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite; }

@keyframes yellow {
  0%, 6% {
    opacity: 1; }
  13%, 100% {
    opacity: 0; } }
      .cs-profil .quote svg.monitor #cyan {
        animation-name: cyan;
        animation-duration: 5s;
        animation-timing-function: ease-in-out;
        animation-delay: 0s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite; }

@keyframes cyan {
  0%, 16% {
    opacity: 1; }
  23%, 100% {
    opacity: 0; } }
      .cs-profil .quote svg.monitor #green {
        animation-name: green;
        animation-duration: 5s;
        animation-timing-function: ease-in-out;
        animation-delay: 0s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite; }

@keyframes green {
  0%, 26% {
    opacity: 1; }
  33%, 100% {
    opacity: 0; } }
      .cs-profil .quote svg.monitor #rose {
        animation-name: rose;
        animation-duration: 5s;
        animation-timing-function: ease-in-out;
        animation-delay: 0s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite; }

@keyframes rose {
  0%, 36% {
    opacity: 1; }
  43%, 100% {
    opacity: 0; } }
      .cs-profil .quote svg.monitor #red {
        animation-name: red;
        animation-duration: 5s;
        animation-timing-function: ease-in-out;
        animation-delay: 0s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite; }

@keyframes red {
  0%, 46% {
    opacity: 1; }
  53%, 100% {
    opacity: 0; } }
      .cs-profil .quote svg.monitor #blue {
        animation-name: blue;
        animation-duration: 5s;
        animation-timing-function: ease-in-out;
        animation-delay: 0s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite; }

@keyframes blue {
  0%, 56% {
    opacity: 1; }
  63%, 100% {
    opacity: 0; } }
      .cs-profil .quote svg.monitor #black {
        animation-name: black;
        animation-duration: 5s;
        animation-timing-function: ease-in-out;
        animation-delay: 0s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite; }

@keyframes black {
  0%, 66% {
    opacity: 1; }
  83%, 100% {
    opacity: 0; } }
    .cs-profil .quote img {
      max-width: 130px; }
  .cs-profil .quote-01, .cs-profil .quote-02, .cs-profil .quote-03, .cs-profil .quote-04, .cs-profil .quote-end {
    background: transparent;
    display: flex; }
    .cs-profil .quote-01.active, .cs-profil .quote-02.active, .cs-profil .quote-03.active, .cs-profil .quote-04.active, .cs-profil .quote-end.active {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      padding: 0 3em;
      background: #3DC39C;
      transition: 0.2s 0.5s background-color ease;
      z-index: 200; }
      @media screen and (max-width: 52.4375em) {
        .cs-profil .quote-01.active, .cs-profil .quote-02.active, .cs-profil .quote-03.active, .cs-profil .quote-04.active, .cs-profil .quote-end.active {
          top: 50vh;
          height: calc(100% - 50vh); } }
      .cs-profil .quote-01.active .quote, .cs-profil .quote-01.active .reveral-quote, .cs-profil .quote-02.active .quote, .cs-profil .quote-02.active .reveral-quote, .cs-profil .quote-03.active .quote, .cs-profil .quote-03.active .reveral-quote, .cs-profil .quote-04.active .quote, .cs-profil .quote-04.active .reveral-quote, .cs-profil .quote-end.active .quote, .cs-profil .quote-end.active .reveral-quote {
        display: block; }
      .cs-profil .quote-01.active .quote, .cs-profil .quote-02.active .quote, .cs-profil .quote-03.active .quote, .cs-profil .quote-04.active .quote, .cs-profil .quote-end.active .quote {
        width: 100%;
        opacity: 0;
        animation-name: fade-in;
        animation-duration: 0s;
        animation-delay: 0.5s;
        animation-fill-mode: forwards;
        animation-timing-function: cubic-bezier(0.1, 0.89, 0.64, 0.99); }
        @media screen and (min-width: 40em) and (max-width: 52.4375em) {
          .cs-profil .quote-01.active .quote p, .cs-profil .quote-02.active .quote p, .cs-profil .quote-03.active .quote p, .cs-profil .quote-04.active .quote p, .cs-profil .quote-end.active .quote p {
            display: inline-block;
            width: 65%; }
          .cs-profil .quote-01.active .quote img, .cs-profil .quote-02.active .quote img, .cs-profil .quote-03.active .quote img, .cs-profil .quote-04.active .quote img, .cs-profil .quote-end.active .quote img {
            display: inline-block;
            width: 30%; } }
  .cs-profil .quote-end.active {
    background: transparent; }
  .cs-profil button.cs-more, .cs-profil button.cs-more2, .cs-profil button.cs-more3,
  .cs-profil button.cs-more4, .cs-profil button.cs-more5, .cs-profil button.cs-close {
    padding: 13px 20px 14px 20px;
    cursor: pointer;
    font-size: 1.8rem;
    position: absolute;
    top: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.05); }
    .cs-profil button.cs-more svg, .cs-profil button.cs-more2 svg, .cs-profil button.cs-more3 svg,
    .cs-profil button.cs-more4 svg, .cs-profil button.cs-more5 svg, .cs-profil button.cs-close svg {
      width: 25px;
      height: 25px; }
  .cs-profil button.cs-more2, .cs-profil button.cs-more3, .cs-profil button.cs-more4,
  .cs-profil button.cs-more5, .cs-profil button.cs-close {
    background: transparent;
    background: rgba(0, 0, 0, 0.1);
    transition: all ease 0.4s; }
    .cs-profil button.cs-more2 .cs-plus-btn, .cs-profil button.cs-more3 .cs-plus-btn, .cs-profil button.cs-more4 .cs-plus-btn,
    .cs-profil button.cs-more5 .cs-plus-btn, .cs-profil button.cs-close .cs-plus-btn {
      transition: all ease 0.4s;
      fill: #63EDC5; }
    .cs-profil button.cs-more2:hover, .cs-profil button.cs-more3:hover, .cs-profil button.cs-more4:hover,
    .cs-profil button.cs-more5:hover, .cs-profil button.cs-close:hover {
      background: #63EDC5; }
      .cs-profil button.cs-more2:hover .cs-plus-btn, .cs-profil button.cs-more3:hover .cs-plus-btn, .cs-profil button.cs-more4:hover .cs-plus-btn,
      .cs-profil button.cs-more5:hover .cs-plus-btn, .cs-profil button.cs-close:hover .cs-plus-btn {
        fill: #fff; }
  .cs-profil button.cs-more {
    right: -15px; }
    .cs-profil button.cs-more .cs-plus-btn {
      transition: all ease 0.4s;
      fill: rgba(255, 255, 255, 0.2);
      fill: #63EDC5; }
    .cs-profil button.cs-more:hover .cs-plus-btn {
      fill: white; }

.cs-slide {
  background: url(../img/blur1.1.jpg) no-repeat 50% 50%/cover;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  opacity: 0;
  animation-name: fade-in;
  animation-duration: 1s;
  animation-delay: 3.4s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.1, 0.89, 0.64, 0.99); }
  @media screen and (max-width: 52.4375em) {
    .cs-slide {
      height: 50vh; } }
  .cs-slide .cs-logo-cyril {
    max-width: 30vw;
    max-height: 70%;
    margin-left: 4%; }
    @media screen and (max-width: 39.9375em) {
      .cs-slide .cs-logo-cyril {
        max-width: 85vw;
        height: auto; } }

/*
==================================================
====================== Intro            			
==================================================
*/
.cs-intro {
  position: relative; }
  .cs-intro .reveral-intro {
    position: absolute;
    animation-name: slide-v;
    height: 100%;
    background: #fff;
    z-index: 10;
    animation-duration: 0.4s;
    animation-delay: 3.4s;
    animation-direction: normal;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    z-index: 10; }
  .cs-intro .cs-devices {
    position: relative;
    height: 420px;
    z-index: 11; }
    @media screen and (max-width: 89.9375em) {
      .cs-intro .cs-devices {
        height: 360px; } }
    @media screen and (max-width: 74.9375em) {
      .cs-intro .cs-devices {
        height: 290px; } }
    @media screen and (max-width: 63.9375em) {
      .cs-intro .cs-devices {
        height: 40vw; } }
    @media screen and (max-width: 52.4375em) {
      .cs-intro .cs-devices {
        height: 40vw;
        margin-top: 80px; } }
    .cs-intro .cs-devices img {
      display: inline-block;
      position: absolute; }
      .cs-intro .cs-devices img.shadow-devices {
        width: 100%;
        height: 50%;
        bottom: 0;
        left: 0;
        opacity: 0;
        animation-name: fade-in;
        animation-duration: 2s;
        animation-delay: 3.5s;
        animation-fill-mode: forwards;
        animation-timing-function: cubic-bezier(0.1, 0.89, 0.64, 0.99); }
      .cs-intro .cs-devices img.minitel {
        width: 290px;
        top: 100px;
        width: 33%;
        max-width: 380px;
        left: 5%;
        opacity: 0;
        animation-name: fade-from-right;
        animation-duration: 0.2s;
        animation-delay: 4.3s;
        animation-fill-mode: forwards;
        animation-timing-function: cubic-bezier(0.1, 0.89, 0.64, 0.99); }
      .cs-intro .cs-devices img.iphone {
        width: 140px;
        top: 70px;
        width: 16%;
        max-width: 180px;
        left: 27%;
        opacity: 0;
        animation-name: fade-from-right;
        animation-duration: 0.2s;
        animation-delay: 4.1s;
        animation-fill-mode: forwards;
        animation-timing-function: cubic-bezier(0.1, 0.89, 0.64, 0.99); }
      .cs-intro .cs-devices img.gameboy {
        left: 285px;
        top: -3vh;
        width: 350px;
        width: 42%;
        max-width: 400px;
        left: 32%;
        opacity: 0;
        animation-name: fade-from-bottom;
        animation-duration: 0.2s;
        animation-delay: 4s;
        animation-fill-mode: forwards;
        animation-timing-function: cubic-bezier(0.1, 0.89, 0.64, 0.99); }
        @media (min-height: 800px) and (min-width: 2100px) {
          .cs-intro .cs-devices img.gameboy {
            top: -2vh; } }
      .cs-intro .cs-devices img.mabrouk {
        left: 470px;
        top: 80px;
        width: 450px;
        width: 50%;
        max-width: 570px;
        left: 49%;
        opacity: 0;
        animation-name: fade-from-left;
        animation-duration: 0.2s;
        animation-delay: 4.2s;
        animation-fill-mode: forwards;
        animation-timing-function: cubic-bezier(0.1, 0.89, 0.64, 0.99); }
  .cs-intro .cs-devices-mobile {
    z-index: 11;
    margin-top: 40px; }
    .cs-intro .cs-devices-mobile img {
      height: 250px;
      width: auto;
      margin: auto; }
      .cs-intro .cs-devices-mobile img.iphone {
        height: 230px; }
  .cs-intro .cs-text {
    z-index: 11;
    font-family: 'SourceSerifPro', Georgia;
    text-align: center;
    font-size: 1.4rem;
    color: #999;
    opacity: 0;
    animation-name: fade-from-left;
    animation-duration: 1s;
    animation-delay: 4.6s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.1, 0.89, 0.64, 0.99); }
    @media screen and (max-width: 74.9375em) {
      .cs-intro .cs-text .show-for-large, .cs-intro .cs-text .plane-animation .cloud2, .plane-animation .cs-intro .cs-text .cloud2, .cs-intro .cs-text .plane-animation .cloud3, .plane-animation .cs-intro .cs-text .cloud3 {
        display: none; } }
    .cs-intro .cs-text .microwave {
      width: 46px;
      display: inline-block;
      margin-top: 10px; }
    @media screen and (max-width: 99.9375em) {
      .cs-intro .cs-text {
        padding-right: 5em; } }
    @media screen and (max-width: 89.9375em) {
      .cs-intro .cs-text {
        padding-right: 2em; } }
    @media screen and (max-width: 74.9375em) {
      .cs-intro .cs-text {
        padding-right: 30px;
        padding-left: 0;
        font-size: 1.2rem; } }
    @media screen and (max-width: 63.9375em) {
      .cs-intro .cs-text {
        padding-right: 2em;
        padding-left: 2em;
        font-size: 1.4rem;
        opacity: 0;
        animation-name: fade-from-top;
        animation-duration: 1s;
        animation-delay: 4.6s;
        animation-fill-mode: forwards;
        animation-timing-function: cubic-bezier(0.1, 0.89, 0.64, 0.99); } }
    @media screen and (max-width: 29.9375em) {
      .cs-intro .cs-text {
        margin-bottom: 30px;
        font-size: 1.2rem;
        min-height: 100px; } }

/*
==================================================
====================== Skills            			
==================================================
*/
.skills .consulting, .skills .webdesign, .skills .development {
  background: #444;
  text-align: center;
  padding: 3em 2em; }
  @media screen and (max-width: 39.9375em) {
    .skills .consulting, .skills .webdesign, .skills .development {
      padding: 2em; } }
  @media screen and (max-width: 29.9375em) {
    .skills .consulting, .skills .webdesign, .skills .development {
      padding: 1.5em; } }
  .skills .consulting img, .skills .webdesign img, .skills .development img {
    max-width: 55px;
    min-height: 60px;
    display: block;
    margin: 0 auto 30px; }
    @media screen and (max-width: 29.9375em) {
      .skills .consulting img, .skills .webdesign img, .skills .development img {
        margin-bottom: 15px; } }
    @media screen and (min-width: 30em) and (max-width: 39.9375em) {
      .skills .consulting img, .skills .webdesign img, .skills .development img {
        display: inline-block;
        float: left;
        margin: 0; } }
  .skills .consulting h3, .skills .webdesign h3, .skills .development h3 {
    color: #999;
    font-weight: 400;
    font-size: 1.1rem; }
    @media screen and (max-width: 99.9375em) {
      .skills .consulting h3, .skills .webdesign h3, .skills .development h3 {
        font-size: 1rem; } }
    @media screen and (max-width: 63.9375em) {
      .skills .consulting h3, .skills .webdesign h3, .skills .development h3 {
        font-size: 1rem; } }
    @media screen and (min-width: 30em) and (max-width: 39.9375em) {
      .skills .consulting h3, .skills .webdesign h3, .skills .development h3 {
        display: inline-block;
        text-align: left;
        float: left;
        max-width: 75%;
        margin-left: 2rem; } }
    @media screen and (max-width: 29.9375em) {
      .skills .consulting h3, .skills .webdesign h3, .skills .development h3 {
        font-size: 0.8rem; } }

.skills .webdesign {
  background: #333; }

.skills .development {
  background: #111; }

/*
==================================================
====================== Clients            			
==================================================
*/
.clients {
  background: #fff; }
  .clients .block-logos {
    padding: 3em 3em 4rem 3rem; }
    @media screen and (max-width: 39.9375em) {
      .clients .block-logos {
        padding: 2em; } }
    .clients .block-logos h2 {
      margin-bottom: 2em;
      color: #444; }
      @media screen and (min-width: 90em) {
        .clients .block-logos h2 {
          margin-bottom: 1.5rem; } }
      @media screen and (max-width: 39.9375em) {
        .clients .block-logos h2 {
          font-size: 1.4rem; } }
      @media screen and (max-width: 29.9375em) {
        .clients .block-logos h2 {
          font-size: 1rem; } }
    .clients .block-logos .columns {
      text-align: center; }
      @media screen and (min-width: 90em) {
        .clients .block-logos .columns img {
          max-height: 70px; }
        .clients .block-logos .columns.meteofrance img, .clients .block-logos .columns.fox img, .clients .block-logos .columns.peugeot img {
          margin-bottom: 2rem; } }
    .clients .block-logos img {
      max-height: 80px;
      display: inline-block;
      align-self: flex-end; }
  .clients .reviews {
    padding: 3rem 0;
    background-image: radial-gradient(1px at -100px 50%, rgba(0, 0, 0, 0.2) 0%, transparent 200px); }
    @media screen and (max-width: 89.9375em) {
      .clients .reviews {
        background-image: radial-gradient(1px at 50% -30vw, rgba(0, 0, 0, 0.3) 0%, transparent 50vw); } }
    @media screen and (max-width: 52.4375em) {
      .clients .reviews .avatar {
        text-align: center; } }
    .clients .reviews .avatar-photo {
      margin: 2rem 0 2rem;
      width: 200px;
      height: 200px;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      overflow: hidden;
      display: inline-block;
      float: right;
      box-shadow: 0 0 0 5px #fff; }
      .clients .reviews .avatar-photo img {
        height: 200px;
        width: auto;
        border-radius: 50%; }
      @media screen and (max-width: 89.9375em) {
        .clients .reviews .avatar-photo {
          margin: 0; } }
      @media screen and (max-width: 52.4375em) {
        .clients .reviews .avatar-photo {
          float: none;
          margin: 0;
          width: 150px;
          height: 150px; }
          .clients .reviews .avatar-photo img {
            height: 150px;
            width: auto; } }
    .clients .reviews .cs-text {
      font-family: 'SourceSerifPro', Georgia;
      color: #808080;
      font-size: 1.3rem;
      padding-right: 3rem;
      padding-left: 2rem;
      background: url(../img/ico-quote.svg) no-repeat 0 0;
      background-size: 25px; }
      @media screen and (max-width: 52.4375em) {
        .clients .reviews .cs-text {
          text-align: center;
          padding: 1rem 3rem;
          background-position: 10% 0;
          background-size: 30px;
          font-size: 1.2em; } }
      @media screen and (max-width: 29.9375em) {
        .clients .reviews .cs-text {
          padding: 1.5rem;
          padding-bottom: 0;
          font-size: 1.1rem; } }
    .clients .reviews .cs-profile {
      padding-left: 2rem; }
      @media screen and (max-width: 52.4375em) {
        .clients .reviews .cs-profile {
          text-align: center;
          padding-right: 1rem;
          padding-left: 1rem; } }
      .clients .reviews .cs-profile span, .clients .reviews .cs-profile a {
        font-family: 'Titillium Web';
        font-weight: 600;
        font-size: 1.1rem;
        line-height: 1; }
      .clients .reviews .cs-profile .cs-name {
        color: #282D33;
        font-size: 1.4rem; }
      .clients .reviews .cs-profile .cs-job {
        color: #808080; }
      .clients .reviews .cs-profile a.cs-compagny {
        color: #3DC39C; }

/*
==================================================
====================== Portfolio            			
==================================================
*/
.cs-portfolio-home {
  background: #fff; }
  .cs-portfolio-home .cs-boostravel > .row, .cs-portfolio-home .cs-otorim > .row, .cs-portfolio-home .cs-citydrive > .row {
    height: 500px; }
  .cs-portfolio-home .cs-boostravel a.cs-infos, .cs-portfolio-home .cs-otorim a.cs-infos, .cs-portfolio-home .cs-citydrive a.cs-infos {
    display: block;
    margin: auto;
    transition: all ease 0.4s;
    padding: 1.2rem 2rem;
    padding-right: 0.8rem;
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0) inset; }
    @media screen and (max-width: 52.4375em) {
      .cs-portfolio-home .cs-boostravel a.cs-infos, .cs-portfolio-home .cs-otorim a.cs-infos, .cs-portfolio-home .cs-citydrive a.cs-infos {
        display: none; } }
    .cs-portfolio-home .cs-boostravel a.cs-infos svg, .cs-portfolio-home .cs-otorim a.cs-infos svg, .cs-portfolio-home .cs-citydrive a.cs-infos svg {
      display: inline-block;
      margin: 0.6rem 0; }
    .cs-portfolio-home .cs-boostravel a.cs-infos .logo, .cs-portfolio-home .cs-otorim a.cs-infos .logo, .cs-portfolio-home .cs-citydrive a.cs-infos .logo {
      fill: #fff; }
    .cs-portfolio-home .cs-boostravel a.cs-infos .text, .cs-portfolio-home .cs-otorim a.cs-infos .text, .cs-portfolio-home .cs-citydrive a.cs-infos .text {
      opacity: 0;
      visibility: hidden;
      height: 0px;
      padding-left: 0;
      margin-left: -15px;
      margin-top: -15px; }
      .cs-portfolio-home .cs-boostravel a.cs-infos .text h3, .cs-portfolio-home .cs-otorim a.cs-infos .text h3, .cs-portfolio-home .cs-citydrive a.cs-infos .text h3 {
        margin-top: 0; }
      .cs-portfolio-home .cs-boostravel a.cs-infos .text p, .cs-portfolio-home .cs-otorim a.cs-infos .text p, .cs-portfolio-home .cs-citydrive a.cs-infos .text p {
        color: #282D33;
        margin: 0;
        font-size: 1rem;
        padding-right: 1.5rem; }
    .cs-portfolio-home .cs-boostravel a.cs-infos:hover, .cs-portfolio-home .cs-otorim a.cs-infos:hover, .cs-portfolio-home .cs-citydrive a.cs-infos:hover {
      width: 570px;
      background: #fff; }
      .cs-portfolio-home .cs-boostravel a.cs-infos:hover .text, .cs-portfolio-home .cs-otorim a.cs-infos:hover .text, .cs-portfolio-home .cs-citydrive a.cs-infos:hover .text {
        opacity: 0;
        animation-name: cta-text;
        animation-duration: 1s;
        animation-delay: 0.4s;
        animation-fill-mode: forwards;
        animation-timing-function: cubic-bezier(0.1, 0.89, 0.64, 0.99); }
      @media (min-width: 1200px) and (max-width: 1300px) {
        .cs-portfolio-home .cs-boostravel a.cs-infos:hover, .cs-portfolio-home .cs-otorim a.cs-infos:hover, .cs-portfolio-home .cs-citydrive a.cs-infos:hover {
          width: 530px; } }
  @media screen and (max-width: 52.4375em) {
    .cs-portfolio-home .cs-boostravel > .row, .cs-portfolio-home .cs-otorim > .row, .cs-portfolio-home .cs-citydrive > .row {
      height: 70vh; }
    .cs-portfolio-home .cs-boostravel a.cs-infos:hover, .cs-portfolio-home .cs-otorim a.cs-infos:hover, .cs-portfolio-home .cs-citydrive a.cs-infos:hover {
      width: auto;
      background: transparent;
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0) inset !important; }
      .cs-portfolio-home .cs-boostravel a.cs-infos:hover .text, .cs-portfolio-home .cs-otorim a.cs-infos:hover .text, .cs-portfolio-home .cs-citydrive a.cs-infos:hover .text {
        display: none; }
      .cs-portfolio-home .cs-boostravel a.cs-infos:hover .logo, .cs-portfolio-home .cs-boostravel a.cs-infos:hover .logo-top, .cs-portfolio-home .cs-boostravel a.cs-infos:hover .logo-bottom, .cs-portfolio-home .cs-otorim a.cs-infos:hover .logo, .cs-portfolio-home .cs-otorim a.cs-infos:hover .logo-top, .cs-portfolio-home .cs-otorim a.cs-infos:hover .logo-bottom, .cs-portfolio-home .cs-citydrive a.cs-infos:hover .logo, .cs-portfolio-home .cs-citydrive a.cs-infos:hover .logo-top, .cs-portfolio-home .cs-citydrive a.cs-infos:hover .logo-bottom {
        fill: #fff !important;
        text-align: center;
        padding-left: 0 !important; } }
  @media print, screen and (min-width: 52.5em) {
    .cs-portfolio-home .cs-boostravel a.cs-infos-mobile, .cs-portfolio-home .cs-otorim a.cs-infos-mobile, .cs-portfolio-home .cs-citydrive a.cs-infos-mobile {
      display: none; } }
  @media screen and (max-width: 52.4375em) {
    .cs-portfolio-home .cs-boostravel a.cs-infos-mobile, .cs-portfolio-home .cs-otorim a.cs-infos-mobile, .cs-portfolio-home .cs-citydrive a.cs-infos-mobile {
      position: relative;
      width: 100%;
      height: 70vh;
      display: inline-block;
      text-align: center; }
      .cs-portfolio-home .cs-boostravel a.cs-infos-mobile svg, .cs-portfolio-home .cs-otorim a.cs-infos-mobile svg, .cs-portfolio-home .cs-citydrive a.cs-infos-mobile svg {
        display: inline-block;
        width: 60px;
        fill: #fff; }
      .cs-portfolio-home .cs-boostravel a.cs-infos-mobile .text, .cs-portfolio-home .cs-otorim a.cs-infos-mobile .text, .cs-portfolio-home .cs-citydrive a.cs-infos-mobile .text {
        margin: auto;
        bottom: 10px;
        position: absolute;
        width: 100%;
        color: #fff; }
        .cs-portfolio-home .cs-boostravel a.cs-infos-mobile .text h3, .cs-portfolio-home .cs-otorim a.cs-infos-mobile .text h3, .cs-portfolio-home .cs-citydrive a.cs-infos-mobile .text h3 {
          font-size: 1.2rem;
          margin-bottom: 0.5rem; }
        .cs-portfolio-home .cs-boostravel a.cs-infos-mobile .text p, .cs-portfolio-home .cs-otorim a.cs-infos-mobile .text p, .cs-portfolio-home .cs-citydrive a.cs-infos-mobile .text p {
          font-size: 0.9rem;
          padding: 0 2rem; } }
  .cs-portfolio-home .cs-boostravel {
    background: url(../img/bg-boostravel-home.jpg) no-repeat 50% 50%/cover; }
    .cs-portfolio-home .cs-boostravel a.cs-infos {
      width: calc(70px + 4rem); }
      .cs-portfolio-home .cs-boostravel a.cs-infos svg {
        width: 70px; }
      .cs-portfolio-home .cs-boostravel a.cs-infos .text h3 {
        color: #F17221; }
      .cs-portfolio-home .cs-boostravel a.cs-infos .text span {
        color: #F17221; }
      .cs-portfolio-home .cs-boostravel a.cs-infos:hover {
        box-shadow: 0 -5px 0 #F17221 inset; }
        .cs-portfolio-home .cs-boostravel a.cs-infos:hover .logo {
          fill: #F17221; }
  .cs-portfolio-home .cs-otorim {
    background: url(../img/bg-otorim-home.jpg) no-repeat 50% 50%/cover; }
    .cs-portfolio-home .cs-otorim a.cs-infos-mobile svg {
      width: 90px; }
    .cs-portfolio-home .cs-otorim a.cs-infos {
      width: calc(110px + 4rem); }
      .cs-portfolio-home .cs-otorim a.cs-infos svg {
        width: 110px; }
      .cs-portfolio-home .cs-otorim a.cs-infos .text h3 {
        color: #CA3000; }
      .cs-portfolio-home .cs-otorim a.cs-infos .text span {
        color: #CA3000; }
      .cs-portfolio-home .cs-otorim a.cs-infos:hover {
        box-shadow: 0 -5px 0 #CA3000 inset; }
        .cs-portfolio-home .cs-otorim a.cs-infos:hover .logo {
          fill: #CA3000; }
  .cs-portfolio-home .cs-citydrive {
    background: url(../img/bg-citydrive-home.jpg) no-repeat 50% 50%/cover; }
    .cs-portfolio-home .cs-citydrive a.cs-infos {
      width: calc(70px + 4rem); }
      .cs-portfolio-home .cs-citydrive a.cs-infos svg {
        width: 70px; }
      .cs-portfolio-home .cs-citydrive a.cs-infos .text h3 {
        color: #32B3C0; }
      .cs-portfolio-home .cs-citydrive a.cs-infos .text span {
        color: #F18534; }
      .cs-portfolio-home .cs-citydrive a.cs-infos:hover {
        box-shadow: 0 -5px 0 #32B3C0 inset; }
        .cs-portfolio-home .cs-citydrive a.cs-infos:hover .logo-top {
          fill: #32B3C0; }
        .cs-portfolio-home .cs-citydrive a.cs-infos:hover .logo-bottom {
          fill: #F18534; }

/*
==================================================
====================== Hire me            			
==================================================
*/
.hire-me {
  background: #fff;
  text-align: center;
  padding: 3rem; }
  @media screen and (max-width: 39.9375em) {
    .hire-me {
      padding: 2rem; } }
  .hire-me p {
    font-family: 'SourceSerifPro', Georgia;
    text-align: center;
    font-size: 1.6rem;
    color: #999; }
    @media screen and (max-width: 52.4375em) {
      .hire-me p {
        font-size: 1.4rem; } }
    @media screen and (max-width: 39.9375em) {
      .hire-me p {
        font-size: 1.3rem; } }
    @media screen and (max-width: 29.9375em) {
      .hire-me p {
        font-size: 1.1rem; } }
  .hire-me a {
    font-size: 1.2rem;
    text-transform: uppercase;
    font-weight: 600;
    padding: 10px 18px;
    color: #3DC39C;
    box-shadow: 0 0 0 2px #3DC39C;
    display: inline-block;
    transition: all ease 0.4s;
    margin-bottom: 1rem; }
    .hire-me a:hover {
      background: #111;
      color: #63EDC5;
      box-shadow: 0 0 0 6px #eee; }
    @media screen and (max-width: 39.9375em) {
      .hire-me a {
        font-size: 1.2rem; } }

/*
==================================================
====================== Footer            			
==================================================
*/
footer .row {
  height: 250px;
  background-color: #1a1a1a;
  border-top: 3px solid #1a1a1a;
  background-image: radial-gradient(ellipse at 50% -20%, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.1) 40%, rgba(255, 255, 255, 0) 70%); }
  footer .row .columns {
    text-align: center; }
    footer .row .columns img {
      max-height: 90px;
      width: auto; }
    footer .row .columns h3 {
      font-size: 0.9rem;
      color: #666;
      margin-top: 30px;
      font-family: 'Titillium Web';
      font-weight: 500;
      text-transform: none; }
      footer .row .columns h3 a {
        color: #999;
        transition: all ease 0.4s; }
        footer .row .columns h3 a:hover {
          color: #63EDC5; }

/* ################################################################################# 
/* ################################################################################# 
/* ################################################################################# 
/* ################################################################################# 
/* ################################################################################# 
/* ################################################################################# 
*/
/*
==================================================
====================== Boostravel            			
==================================================
*/
/*
==================================================
====================== Header            			
==================================================
*/
.bs-detail {
  background: #282D33;
  color: #fff;
  padding: 2rem 3rem;
  opacity: 0;
  animation-name: fade-in;
  animation-duration: 0;
  animation-delay: 3.4s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.1, 0.89, 0.64, 0.99); }
  .bs-detail h1, .bs-detail .cs-contact-modal h3, .cs-contact-modal .bs-detail h3, .bs-detail .cs-menu-modal h3, .cs-menu-modal .bs-detail h3 {
    font-size: 1.4rem;
    margin-bottom: 2rem;
    margin-top: 1rem; }
  @media screen and (max-width: 52.4375em) {
    .bs-detail {
      padding: 1.5rem 2rem; }
      .bs-detail h1, .bs-detail .cs-contact-modal h3, .cs-contact-modal .bs-detail h3, .bs-detail .cs-menu-modal h3, .cs-menu-modal .bs-detail h3 {
        margin-top: 0;
        margin-bottom: 1rem; } }
  @media screen and (max-width: 29.9375em) {
    .bs-detail p {
      font-size: 1rem; } }

.bs-visual {
  background: url(../img/boostravel/bg-boostravel.jpg) no-repeat 50% 40%/cover;
  height: 500px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  opacity: 0;
  animation-name: fade-in;
  animation-duration: 0;
  animation-delay: 3.4s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.1, 0.89, 0.64, 0.99); }
  .bs-visual img {
    margin-top: -10rem;
    margin-left: -55%;
    height: auto; }
    @media screen and (max-width: 29.9375em) {
      .bs-visual img {
        max-width: 140%;
        margin-left: -80%; } }
  @media screen and (max-width: 52.4375em) {
    .bs-visual {
      height: 60vh; } }

/*
==================================================
====================== Magazine            			
==================================================
*/
.bs-magazine {
  background: #fff;
  background-image: radial-gradient(1px at 50% -30vw, rgba(0, 0, 0, 0.3) 0%, transparent 50vw);
  position: relative;
  padding: 0 4rem;
  text-align: center;
  text-shadow: 0 2px 0 rgba(255, 255, 255, 0.8);
  opacity: 0;
  animation-name: fade-in;
  animation-duration: 2s;
  animation-delay: 3.5s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.1, 0.89, 0.64, 0.99); }
  @media screen and (max-width: 39.9375em) {
    .bs-magazine {
      padding: 0 2.5rem; } }
  @media screen and (max-width: 29.9375em) {
    .bs-magazine {
      padding: 0 1.5rem; } }
  .bs-magazine .text {
    font-family: 'SourceSerifPro', Georgia;
    text-align: center;
    color: #999; }
    @media screen and (min-width: 90em) {
      .bs-magazine .text {
        padding: 6.5rem 5rem 6.5rem 14rem;
        font-size: 1.4rem; } }
    @media screen and (max-width: 89.9375em) {
      .bs-magazine .text {
        padding: 4.5rem 3rem;
        font-size: 1.3rem; } }
    @media screen and (max-width: 74.9375em) {
      .bs-magazine .text {
        padding: 3.5rem 0rem;
        font-size: 1.2rem; } }
    @media screen and (max-width: 52.4375em) {
      .bs-magazine .text {
        order: 2;
        margin-top: 13rem; } }
    @media screen and (max-width: 39.9375em) {
      .bs-magazine .text {
        margin-top: 35vw; } }
    @media screen and (max-width: 29.9375em) {
      .bs-magazine .text {
        margin-top: 35vw;
        font-size: 1.1rem; } }
  .bs-magazine .image {
    position: absolute;
    margin-top: -80px;
    margin-left: auto;
    margin-right: auto; }
    .bs-magazine .image img {
      width: 100%;
      max-width: 450px; }
    @media screen and (min-width: 90em) {
      .bs-magazine .image img {
        max-width: 600px; } }
    @media screen and (min-width: 100em) {
      .bs-magazine .image img {
        max-width: 550px; } }
    @media screen and (max-width: 63.9375em) {
      .bs-magazine .image {
        margin-top: -40px;
        margin-left: -3rem; }
        .bs-magazine .image img {
          width: 80%; } }
    @media screen and (max-width: 52.4375em) {
      .bs-magazine .image {
        margin-left: 1vw; }
        .bs-magazine .image img {
          width: 100%; } }
    @media screen and (max-width: 39.9375em) {
      .bs-magazine .image {
        margin-left: -2rem; }
        .bs-magazine .image img {
          width: 100%; } }
    @media screen and (max-width: 29.9375em) {
      .bs-magazine .image {
        margin-left: -3rem;
        margin-top: -30px; }
        .bs-magazine .image img {
          width: 90%; } }

/*
==================================================
====================== Plane animation            			
==================================================
*/
.plane-animation .container {
  height: 300px;
  background: #F68700;
  background: -moz-linear-gradient(left, #F68700 0%, #DA3A2D 100%);
  background: -webkit-linear-gradient(left, #F68700 0%, #DA3A2D 100%);
  background: linear-gradient(to right, #F68700 0%, #DA3A2D 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color1', endColorstr='$color2',GradientType=1 );
  overflow: hidden;
  position: relative; }

.plane-animation .cloud1, .plane-animation .cloud2, .plane-animation .cloud3, .plane-animation .cloud4 {
  position: absolute;
  background: url(../img/ico-cloud.svg) no-repeat;
  width: 1000px;
  height: 800px;
  animation-timing-function: linear;
  animation-iteration-count: infinite; }

.plane-animation .cloud1 {
  transform: rotate(-25deg) translateZ(0);
  animation-name: cloud1;
  animation-duration: 25s; }

.plane-animation .cloud2 {
  top: -2000px;
  opacity: 0.4;
  transform: rotate(155deg) translate3d(0, 0, 0);
  animation-name: cloud2;
  animation-duration: 35s; }

.plane-animation .cloud3 {
  top: 0px;
  opacity: 0.4;
  transform: rotate(155deg) translate3d(0, 0, 0);
  animation-name: cloud3;
  animation-duration: 38s; }

.plane-animation .cloud4 {
  top: 0px;
  transform: rotate(-25deg) translate3d(0, 0, 0);
  animation-name: cloud4;
  animation-duration: 24s; }

.plane-animation .plane {
  width: 800px;
  height: 100px;
  background: url(../img/ico-plane.svg) no-repeat;
  position: absolute;
  top: 250px;
  left: calc(50% - 700px);
  animation-name: plane;
  animation-duration: 2s;
  animation-delay: 0;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite; }

.plane-animation .plane-shadow {
  width: 60px;
  height: 60px;
  background: url(../img/ico-plane-shadow.svg) no-repeat;
  position: absolute;
  top: calc(50% - 25px);
  left: calc(50% - 5px);
  opacity: 0.2;
  animation-name: plane-shadow;
  animation-duration: 2s;
  animation-delay: 0;
  animation-fill-mode: forwards;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite; }

/*
==================================================
====================== List skill       			
==================================================
*/
.bs-skills .skill-list {
  background: #282D33;
  padding: 3rem 3rem 2rem 3rem;
  color: #fff; }
  .bs-skills .skill-list ul {
    padding: 0;
    margin: 0; }
  .bs-skills .skill-list li {
    list-style: none;
    display: block;
    margin-bottom: 1.5rem;
    font-size: 'Montserrat', arial;
    text-transform: uppercase;
    font-size: 1.2rem; }
    .bs-skills .skill-list li span {
      color: #3DC39C;
      margin-right: 2rem;
      font-size: 1.5rem;
      vertical-align: baseline; }
  @media screen and (max-width: 29.9375em) {
    .bs-skills .skill-list {
      padding: 2rem 1rem 1rem 1rem; }
      .bs-skills .skill-list li {
        font-size: 0.9rem; }
        .bs-skills .skill-list li span {
          margin-right: 1rem; } }

/*
==================================================
====================== Wireframe       			
==================================================
*/
.bs-wireframe {
  text-align: center;
  text-shadow: 0 0 20px rgba(0, 0, 0, 0.6);
  height: 50vh;
  min-height: 300px;
  background: url(../img/boostravel/boostravel-wireframe.jpg) 50% 50%/cover;
  background-attachment: fixed; }
  .bs-wireframe .number {
    color: #fff;
    padding: 3.3rem 1rem;
    border-radius: 50%;
    height: 200px;
    width: 200px;
    display: inline-block;
    background: #63EDC5;
    background: -moz-linear-gradient(left, #63EDC5 0%, #3DC39C 100%);
    background: -webkit-linear-gradient(left, #63EDC5 0%, #3DC39C 100%);
    background: linear-gradient(to right, #63EDC5 0%, #3DC39C 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color1', endColorstr='$color2',GradientType=1 ); }
    .bs-wireframe .number span {
      font-family: 'Montserrat', arial;
      font-size: 4rem;
      font-weight: 700;
      line-height: 3rem; }

/*
==================================================
====================== UI     			
==================================================
*/
.ui {
  /* End Wallpaper */ }
  .ui .wallpaper {
    background: url("../img/boostravel/bg03.jpg") no-repeat 50% 100%/cover;
    height: 50vh;
    min-height: 400px;
    max-height: 620px;
    display: flex;
    position: relative;
    z-index: 50; }
    .ui .wallpaper .punchline {
      position: absolute;
      left: 30%;
      top: 80px; }
    .ui .wallpaper .laptop {
      width: 60%;
      position: absolute;
      bottom: -70px;
      align-self: flex-end; }
    .ui .wallpaper .mobile {
      width: 15%;
      position: absolute;
      bottom: -90px;
      left: 50%;
      align-self: flex-end; }
    @media screen and (min-width: 100em) {
      .ui .wallpaper {
        min-height: calc( (50vh + 35vw) / 2); } }
    @media screen and (max-width: 74.9375em) {
      .ui .wallpaper .punchline {
        width: 350px;
        left: 25%; }
      .ui .wallpaper .laptop {
        width: 75%;
        bottom: -50px; }
      .ui .wallpaper .mobile {
        width: 20%;
        bottom: -70px;
        left: 50%; } }
    @media screen and (max-width: 52.4375em) {
      .ui .wallpaper .laptop {
        min-width: 560px;
        bottom: -50px;
        left: -5%; }
      .ui .wallpaper .mobile {
        width: 25%;
        min-width: 170px;
        bottom: -70px;
        left: 53%; } }
    @media screen and (max-width: 39.9375em) {
      .ui .wallpaper {
        min-height: auto;
        height: 300px; }
        .ui .wallpaper .punchline {
          width: 300px;
          top: 40px;
          left: 15%; }
        .ui .wallpaper .laptop {
          min-width: 500px;
          width: 75%;
          bottom: -70px; }
        .ui .wallpaper .mobile {
          width: 30%;
          min-width: auto;
          bottom: -90px;
          left: 60%; } }
    @media screen and (max-width: 29.9375em) {
      .ui .wallpaper .punchline {
        width: 80%;
        left: 10%; }
      .ui .wallpaper .mobile {
        width: 70%;
        left: 15%;
        bottom: auto;
        top: 120px; } }
  .ui .assets {
    min-height: 50vh;
    background: #fff url(../img/boostravel/world-map.png) no-repeat 50% 50%/cover;
    position: relative;
    text-align: center;
    overflow: hidden; }
    @media print, screen and (min-width: 64em) {
      .ui .assets {
        height: calc( 0 + 80vh);
        height: 400px; } }
    .ui .assets .shadow {
      height: 100px;
      width: 100%;
      position: relative;
      background: rgba(0, 0, 0, 0.15);
      background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.15) 0%, transparent 100%);
      background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.15) 0%, transparent 100%);
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.15) 0%, transparent 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color1', endColorstr='$color2',GradientType=0 ); }
    .ui .assets .shadow-white {
      height: 100px;
      width: 100%;
      position: absolute;
      bottom: 0;
      background: rgba(255, 255, 255, 0);
      background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, white 100%);
      background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, white 100%);
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color1', endColorstr='$color2',GradientType=0 ); }
    .ui .assets .colors {
      text-align: center;
      margin-top: calc( 100vw / 2); }
      @media print, screen and (min-width: 30em) {
        .ui .assets .colors {
          margin-top: 80px; } }
      @media screen and (min-width: 75em) {
        .ui .assets .colors {
          margin-top: 120px; } }
      .ui .assets .colors .columns {
        margin-top: 20px; }
    .ui .assets .fonts {
      margin-top: 20px; }
      @media print, screen and (min-width: 30em) {
        .ui .assets .fonts {
          margin-bottom: 100px; } }
      @media print, screen and (min-width: 52.5em) {
        .ui .assets .fonts {
          margin-top: 100px; } }
      @media screen and (min-width: 75em) {
        .ui .assets .fonts {
          margin-top: 140px;
          margin-left: 3vw; } }

/* End UI */
/*
==================================================
====================== Masonry     			
==================================================
*/
/* ---- grid ---- */
.bs-masonry {
  background: #fff;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 60px;
  /* clearfix */
  /* ---- grid-item ---- */
  --grid-sizer: calc(100% / 6); }
  @media screen and (min-width: 90em) {
    .bs-masonry {
      padding-right: 40px;
      padding-left: 40px; } }
  .bs-masonry .grid:after {
    content: '';
    display: block;
    clear: both; }
  @media screen and (min-width: 1020px) and (min-height: 100px) {
    .bs-masonry {
      margin-top: -20vh; } }
  @media screen and (max-width: 1200px) {
    .bs-masonry .grid-item, .bs-masonry .grid-sizer {
      --grid-sizer: calc(100% / 4); } }
  @media screen and (max-width: 900px) {
    .bs-masonry .grid-item, .bs-masonry .grid-sizer {
      --grid-sizer: calc(100% / 3); } }
  @media screen and (max-width: 700px) {
    .bs-masonry .grid-item, .bs-masonry .grid-sizer {
      --grid-sizer: calc(100% / 2); } }
  .bs-masonry .grid-sizer {
    width: 10%;
    width: var(--grid-sizer); }
  .bs-masonry .grid-item {
    float: left;
    padding: 20px;
    width: 20%;
    width: calc(100% / 6);
    width: var(--grid-sizer);
    opacity: 0.8;
    transition: opacity ease 0.3s; }
    .bs-masonry .grid-item:hover {
      opacity: 1; }
    @media screen and (min-width: 90em) {
      .bs-masonry .grid-item {
        padding: 40px; } }
    .bs-masonry .grid-item.w1 {
      width: calc( 100% / 6); }
    .bs-masonry .grid-item.w2 {
      width: calc( 100% / 6 * 2); }
    .bs-masonry .grid-item.w3 {
      width: calc( 100% / 6 * 3); }
    .bs-masonry .grid-item.w4 {
      width: calc( 100% / 6 * 4); }
    .bs-masonry .grid-item.w5 {
      width: calc( 100% / 6 * 5); }
    .bs-masonry .grid-item.w6 {
      width: calc( 100% / 6 * 6); }
    .bs-masonry .grid-item.w1 {
      width: calc( var(--grid-sizer) *1); }
    .bs-masonry .grid-item.w2 {
      width: calc( var(--grid-sizer) * 2); }
    .bs-masonry .grid-item.w3 {
      width: calc( var(--grid-sizer) * 3); }
    .bs-masonry .grid-item.w4 {
      width: calc( var(--grid-sizer) * 4); }
    .bs-masonry .grid-item.w5 {
      width: calc( var(--grid-sizer) * 5); }
    .bs-masonry .grid-item.w6 {
      width: calc( var(--grid-sizer) * 6); }
    @media screen and (max-width: 700px) {
      .bs-masonry .grid-item.w2 {
        width: calc( var(--grid-sizer) * 1); }
      .bs-masonry .grid-item.w3 {
        width: calc( var(--grid-sizer) * 2); } }
    @media screen and (max-width: 460px) {
      .bs-masonry .grid-item.w2 {
        width: calc( var(--grid-sizer) * 2); }
      .bs-masonry .grid-item.w1 {
        width: calc( var(--grid-sizer) * 2); } }
    .bs-masonry .grid-item.w30 {
      width: 30%; }
    .bs-masonry .grid-item.w40 {
      width: 40%; }
    .bs-masonry .grid-item.w60 {
      width: 60%; }
    .bs-masonry .grid-item.w70 {
      width: 70%; }
    .bs-masonry .grid-item img:not(.no-shadow) {
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.3); }

/*
==================================================
====================== Hire me            			
==================================================
*/
.bs-hire-me {
  background: #eee;
  background-image: radial-gradient(1px at 50% -30vw, rgba(0, 0, 0, 0.3) 0%, transparent 40vw), radial-gradient(1px at 50% 300px, white 0%, transparent 40vw); }
  .bs-hire-me a:hover {
    box-shadow: 0 0 0 3px #fff; }

/*
==================================================
====================== Other portfolio     			
==================================================
*/
.bs-portfolio .cs-boostravel > .row, .bs-portfolio .cs-otorim > .row, .bs-portfolio .cs-citydrive > .row {
  height: 300px; }
