@charset 'utf-8';

@import 'owl.carousel.min';
@import 'owl.theme.default.min';
@import 'settings';
@import 'foundation';
@import 'motion-ui';
//@import 'margin';
@import 'fonts';
@import 'keyframes';
@import 'fakeLoader';




@include foundation-global-styles;
// @include foundation-grid;
@include foundation-flex-grid;
// @include foundation-typography;
// @include foundation-button;
// @include foundation-forms;
// @include foundation-range-input;
// @include foundation-accordion;
// @include foundation-accordion-menu;
// @include foundation-badge;
// @include foundation-breadcrumbs;
// @include foundation-button-group;
// @include foundation-callout;
// @include foundation-card;
// @include foundation-close-button;
// @include foundation-menu;
// @include foundation-menu-icon;
// @include foundation-drilldown-menu;
// @include foundation-dropdown;
// @include foundation-dropdown-menu;
// @include foundation-responsive-embed;
// @include foundation-label;
// @include foundation-media-object;
// @include foundation-off-canvas;
// @include foundation-orbit;
// @include foundation-pagination;
// @include foundation-progress-bar;
// @include foundation-progress-element;
// @include foundation-meter-element;
// @include foundation-slider;
// @include foundation-sticky;
// @include foundation-reveal;
// @include foundation-switch;
// @include foundation-table;
// @include foundation-tabs;
// @include foundation-thumbnail;
// @include foundation-title-bar;
// @include foundation-tooltip;
// @include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-flex-classes;

//@include motion-ui-transitions;
//@include motion-ui-animations;


/*
==================================================
====================== Mixin & Variables            			
==================================================
*/
// Colors
$light-green: #63EDC5;
$dark-green: #3DC39C;
$light-blue: #86C9EF;
$grey: #7B9CA2;
$light-grey: #b2b2b2;
$dark-grey: #282D33;
$red: #FF6E4A;
$boostravel-red : #E74D37;
$boostravel-orange : #F68F00;

// Générate alpha between 0.2 and 0.6
$alpha : random(40) / 100 + 0.2;

// anti spam
.g-reverse{
	unicode-bidi:bidi-override;
	direction: rtl;
}

// @include transition(0.8s); 
@mixin transition($duration){
	transition : all ease $duration;
}

// =================== Text & background
//@include color ($light-green, $dark-grey);
@mixin color ($text-color, $bg-color){
	color: $text-color;
	background: $bg-color;
}


// =================== Gradient

// background: radial-gradient(ellipse at center, rgba(74, 74, 74, 1) 0%, rgba(37, 37, 37, 0) 60%);

// @include h-gradient(rgba(0,0,0,0.2), rgba(0,0,0,0));
@mixin h-gradient($color1, $color2){
	background: $color1;
	background: -moz-linear-gradient(left,  $color1 0%, $color2 100%);
	background: -webkit-linear-gradient(left,  $color1 0%,$color2 100%);
	background: linear-gradient(to right,  $color1 0%,$color2 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color1', endColorstr='$color2',GradientType=1 );
}

@mixin v-gradient($color1, $color2){
	background: $color1;
	background: -moz-linear-gradient(top,  $color1 0%, $color2 100%);
	background: -webkit-linear-gradient(top,  $color1 0%,$color2 100%);
	background: linear-gradient(to bottom,  $color1 0%,$color2 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color1', endColorstr='$color2',GradientType=0 );
}
@mixin diag-gradient($color1, $color2){
	background: $color1;
	background: -moz-linear-gradient(-45deg,  $color1 0%, $color2 100%);
	background: -webkit-linear-gradient(-45deg,  $color1 0%,$color2 100%);
	background: linear-gradient(-45deg,  $color1 0%,$color2 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color1', endColorstr='$color2',GradientType=1 );
}
// =================== Animation

// ==== Block reveral animation
//@include block-reveral (&name, $color, $zindex, $delay, $duration, $reverse);
@mixin reveral-header($name, $color, $zindex, $delay, $duration, $normal){
	position: absolute;
	animation-name: $name;
	height: 100%;
	background:$color;
	z-index: $zindex;
	animation-duration: $duration;
	animation-delay: $delay;
	animation-direction: $normal;
	animation-timing-function: ease-in-out;
}
//@include block-reveral (&name, $color, $zindex, $delay, $duration, $reverse);
@mixin block-reveral($name, $color, $zindex, $delay, $duration, $normal){
	position: absolute;
	animation-name: $name;
	height: 100%;
	background:$color;
	z-index: $zindex;
	animation-duration: $duration;
	animation-delay: $delay;
	animation-direction: $normal;
	animation-timing-function: ease-in-out;
}

// ==== Content animation
// @include content-show (fade-in, 1s, 1s, 1em, 0);
@mixin content-show ($animation-name, $delay, $duration){
	opacity: 0;
	animation-name: $animation-name;
	animation-duration: $duration;
	animation-delay: $delay;
	animation-fill-mode: forwards;
	animation-timing-function: cubic-bezier(.1,.89,.64,.99);
}



/*
==================================================
====================== Global            			
==================================================
*/

body{
	background:#ddd;
	font-family: 'Titillium Web';
	font-style: normal;
	font-weight: 400;
	color : $dark-grey;
	font-size: 1.1rem;
	.cs-dark, .cs-dark2{
		position: fixed;
		z-index: 200;
		width: 100vw;
		height: 100vh;
		top: 0;
		right: 0;
		background: rgba(0,0,0,0.7);
		opacity: 0;
		display: none;
		visibility: hidden;
		&.active{
			@include content-show (fade-in, 0s, 0.5s);
			display: block;
			visibility: visible;
		}
		.cs-dark2{
			z-index: 99;
		}
	}
	&>.cs-container{
		padding: 5%;
		padding-top: 0;

		@include breakpoint(large down) {
			padding: 80px;
			padding-top: 0;
		}
		@include breakpoint(xmedium down) {
			padding: 6%;
			padding-top: 0;
		}
	}
}

.block{
	height: 50px;width: 50px;
	background:#000;
}
.hidden{
	display: none;
}
h1, h2, h3{
	font-family: 'Montserrat', 'Titillium Web', arial;
	text-transform: uppercase;
	padding: 0;
	margin: 0;
}
a {
	text-decoration: none;
	color: $light-green;
}


/*
==================================================
====================== Modal Contact            			
==================================================
*/
.cs-contact-modal, .cs-menu-modal{
	opacity: 0;
	visibility: hidden;
	display: none;
	position: fixed;
	width: 50vw;
	top: 35vh;
	left: 0;
	right: 0;
	margin: auto;
	max-width: 680px;
	background:#fff;
	z-index: 250;
	padding: 2.5rem 3rem;
	border-bottom: 5px solid $dark-green;
	box-shadow: 0 1rem 2rem rgba(0,0,0,0.5);
	color: #999;

	h3{
		@extend h1;
		color: $dark-green;
	}

	a.cs-close{
		position: absolute;
		top: 0;
		right: 0;
		padding: 1rem 1.2rem;
		box-shadow: 0 1px 0 0 #fff inset;
		transition: all ease 0.3s;
		.icon-ico-plus{
			color: $light-grey;
			display: inline-block;
			transform: rotate(45deg);
		}

		&:hover{
			box-shadow: 0 80px 0 0 $dark-green inset;
			.icon-ico-plus{
				color: #fff;
			}
		}
	}

	.cs-tel{
		display: inline-block;
		color: $dark-grey;
		font-size: 1.2rem;
		&:after{
			@extend .g-reverse;
			content: "66.38.01.41.60";
		}

	}
	.cs-mail{
		color: $dark-green;
		display: inline-block;
		font-size: 1.2rem;
		&:after{
			@extend .g-reverse;
			content: "rf.hcuarts\40liryc";
		}
	}

	@include breakpoint(large down) {
		top: 30vh;
		width: 60vw;
		padding: 2.5rem;
	}
	@include breakpoint(medium down) {
		top: 25vh;
		width: 75vw;
		padding: 2rem;
	}
	@include breakpoint(small down) {
		top: 20vh;
		width: 85vw;
		padding: 2rem;
	}

	&.active{
		display: inline-block;
		@include content-show (contact-modal, 0s, 0.5s);
	}
	&.inactive{
		display: inline-block;
		@include content-show (contact-modal-off, 0s, 0.5s);
	}

	
}
.cs-menu-modal{
	max-width: 400px;
	padding: 2rem;
	top: 20vh;
	nav, .cs-social-link{
		padding: 0 1rem;
		background:#fff;
		text-transform: uppercase;
		text-align: center;
		ul{padding: 0;margin: 0;}
		li{list-style: none; display: block;}
		a{
			padding: 1rem;
			font-size: 1.4rem;
			font-weight: 600;
			color: #666;
			display: inline-block;
			&:hover{
				color: $dark-green;
			}
		}
	}
	.cs-social-link{
		margin-top: 1rem;
		li{display: inline-block;}
		@extend .hide-for-xsmall;
	}

}
/*
==================================================
====================== Logo          			
==================================================
*/

.cs-logo{

	padding-left: 0;
	@extend .xsmall-5;
	@extend .small-12;
	@include content-show (fade-from-top, 3.8s, 0.5s); //1.8

	.cs-flag{
		height: 80px;
		padding: 17px 20px;
		@include v-gradient(#111, #333);
		color: $light-green;
		display: inline-block;
		font-size: 1.8rem;
		//text-shadow: 0 1px 0 #fff;

		@include breakpoint(small down) {
			height: 60px;
			padding: 13px 16px;
			@include v-gradient(#111, #333);
			color: $light-green;
			display: inline-block;
			font-size: 1.4rem;
		}
	}

	
}



/*
==================================================
====================== Top bar            			
==================================================
*/

.cs-top-bar{
	height: 80px;
	margin-bottom: 40px;
	
	@include breakpoint(small down) {
		margin-bottom: 0;
	}

	.cs-social-link{
		text-align: right;
		padding-right: 0;
		ul{
			margin: 0;
		}
		li{
			list-style: none;
			display: inline-block;
			text-transform: uppercase;
			font-weight: 600;
			a{
				font-size: 1.5rem;
				display: inline-block;
				padding: 20px;
				color: $light-grey;
				transition: all ease 0.3s;
				text-shadow: 0 1px 0 #fff;
				&:hover{
					//background:rgba(255,255,255, 0.2);
					//@include v-gradient(rgba(255,255,255,0), rgba(255,255,255,1));
					//box-shadow: 0 -2px 0 0  $dark-green inset;
					color: $dark-green;
					text-shadow: 0 1px 20px #fff;
				}
				@include breakpoint(medium down) {
					font-size: 1.3rem;
					padding: 20px 1rem;
				}
				@include breakpoint(small down) {
					&:not(.cs-burger){display: none;}
				}
			}
			a.cs-burger{
				font-size: 1.5rem;
				background:rgba(255,255,255, 1);
				color: $dark-green;
				margin-left: 10px;
				box-shadow: 0 0 0 0 #fff inset;
				text-shadow: none;
				height: 80px;

				&:hover{
					box-shadow: 0 -80px 0 0 $dark-green inset;
					color: #fff;
					text-shadow: none;
				}

				@include breakpoint(small down) {
					height: 60px;
					padding: 13px 1rem;
					background:transparent;
					color: $light-grey;
				}
			}
			&:nth-child(1){
				@include content-show (fade-from-top, 4.2s, 0.5s); // 2.2
				margin-left: 0;
			}
			&:nth-child(2){
				@include content-show (fade-from-top, 4s, 0.5s); //2
			}
			&:nth-child(3){
				@include content-show (fade-from-top, 3.8s, 0.5s); //1.8
			}
			&:nth-child(4){
				@include content-show (fade-from-top, 3.6s, 0.5s); //1.6
			}
		}


	}
}

/*
==================================================
====================== Header / Slider     			
==================================================
*/


header{
	position: relative;
	overflow: hidden;
	&>.columns>.row{
		//height: 60vh;
		// ==== responsive
		@include breakpoint(xxlarge) {
			//height: 60vh;
		}
	}
}

$header-delay: 2.8s; //0.8
$header-duration: 1s;
.reveral-header{
	@include breakpoint(xxxlarge) {
		// (&name, $color, $zindex, $delay, $duration, $reverse)
		@include reveral-header (header-xlarge, $dark-grey, 150, $header-delay, $header-duration, normal);
	}
	@include breakpoint(xxlarge down) {
		@include reveral-header (header-large, $dark-grey, 150, $header-delay, $header-duration, normal);
	}
	// @include breakpoint(xlarge down) {
	// 	@include reveral-header (header-medium, #fff, 150, 0.8s, 1s, normal);
	// }
	@include breakpoint(large down) {
		@include reveral-header (header-small, $dark-grey, 150, $header-delay, $header-duration, normal);
	}
	@include breakpoint(medium down) {
		@include reveral-header (header-small, $dark-grey, 150, $header-delay, $header-duration, normal);
	}
	
}


// =================== Side Profile

.cs-profil{
	color: #fff;
	background: $dark-grey;
	//background:#111;

	// ==== grid
	@extend .xxxlarge-7;
	@extend .xlarge-8;
	@extend .xmedium-10;
	@extend .small-24;
	// ==== order
	@extend .small-order-2;
	@extend .xmedium-order-1;
	// ==== animation
	@include content-show (fade-in, 3.4s, 0); //0.4


	// ==== Presentation
	.cs-presentation{
		padding: 0 1.5em 1.5em 1.5em;
		position: relative;


		img.photo{
			margin-left: calc(-15px - 1.5em);
			max-width: calc(100% + 30px + 3em);
			display: block;

			@include breakpoint(medium only) {
				width: 350px;
				position: absolute;
				top: 0;
				left: 0;
			}
			@include content-show (fade-in, 4s, 6s); //2s
		}	
		@include breakpoint(xsmall only) {
			text-align: right;
			div{
				text-align: left;
			}
			img.photo{
				transform: scaleX(-1);
				width: 400px;
				display: inline;
				margin-right: calc(-15px - 1.5em);
			}
		}
		div {
			@include breakpoint(medium only) {
				display: inline-block;
				max-width: 70%;
				margin-left: 32%;
			}
			h2{
				color: rgba(255,255,255, 0.15);
				font-size: 2rem;
				margin-top: -140px;
				@include content-show (fade-from-top, 4s, 2s); //2s
				@include breakpoint(medium only) {
					margin-top: 0.7em;
				}
				@include breakpoint(xsmall down) {
					margin-top: -140px;
				}
				@include breakpoint(small down) {
					margin-top: -100px;
				}
			}
			p{	
				font-size: 1rem;
				@include content-show (fade-from-bottom, 4s, 2s); //2s
				&.paragraph2{
					@extend .hide-for-small-only;
					@media (max-height: 600px) and (max-width:1400px) {
						display: none;
					}
				}
				@include breakpoint(small down) {
					font-size: 1rem;
				}
			}
		}
		
	}


	// ==== En savoir plus
	a{
		&.cs-more{
			font-size: 1.1rem;
			padding: 8px 16px;
			background: #111;
			display: inline-block;
			margin: 0.8em 1px;
			transition: all ease 0.3s;
			@include content-show (fade-in, 5s, 2s); //3s
			@include breakpoint(medium only) {
				position: absolute;
				bottom: 1rem;
				left: 5%;
			}
			&:hover {
				box-shadow: 0 0 0 1px #fff;
				background:$dark-green;
				color: #fff;
			}
		}
		&.cs-more2, &.cs-more2, &.cs-more3, &.cs-close {
			font-size: 1.1rem;
			display: inline-block;
			background: rgba(0,0,0,0.2);
			padding: 8px 16px;
			margin-top: 1.5rem;
			transition: all ease 0.3s;
			&:hover{
				background:#fff;
				color: $dark-green;
			}
		}
	}



	// ==== Anim Quote
	.reveral-quote{
		//margin-top: -3em;
		display: none;
		@include block-reveral (slide-h, #111, 300, 0s, 1s, normal);
		//reponsive
		@include breakpoint(xmedium down) {
			padding-top: 0;
		}
	}

	// ==== Quote
	.quote{
		display: none;
		//opacity: 0.5;
		text-align: center;
		color: #fff;
		font-size: 1.8rem;

		@include breakpoint(xsmall down) {
			font-size: 1.5rem;
		}


		svg{
			vertical-align: top;
		}
		// Pixel Perfect
		svg.cyril{
			width: 150px;
			.sparkle{
				animation-name: sparkle;
				animation-duration: 0.1s;
				animation-timing-function: ease-in-out;
				animation-delay: 0;
				animation-fill-mode: forwards;
				animation-direction: alternate;
				animation-iteration-count: infinite;

				@keyframes sparkle {
					0%   { transform: translateX(0) scale(1);}
					100% { transform: translateX(1px) scale(1.01);}
				}
			}
			.sparkle-min{
				animation-name: sparkle-min;
				animation-duration: 0.1s;
				animation-timing-function: ease-in-out;
				animation-delay: 0;
				animation-fill-mode: forwards;
				animation-direction: alternate;
				animation-iteration-count: infinite;

				@keyframes sparkle-min {
					0%   { transform: translateY(-2px) ;}
					100% { transform: translateY(0) ;}
				}
			}
		}

		// Highlander
		svg.highlander{
			width: 170px;
			.light{
				opacity: 0;
				animation-name: light;
				animation-duration: 3s;
				animation-timing-function: ease-in-out;
				animation-delay: 0s;
				animation-fill-mode: forwards;
				animation-iteration-count: infinite;
				transform-origin: 30px 24px;

				@keyframes light {
					0%   { opacity:0;transform:rotate(0deg);}
					15%   { opacity:1;}
					30%, 100% { opacity:0; transform:rotate(360deg);}
				}
			}
		}

		// Monitor calibration
		svg.monitor{
			width: 170px;

			#yellow{
				animation-name: yellow;
				animation-duration: 5s;
				animation-timing-function: ease-in-out;
				animation-delay: 0s;
				animation-fill-mode: forwards;
				animation-iteration-count: infinite;
				@keyframes yellow {
					0%, 6%  {opacity:1;}
					13%, 100%  {opacity:0;}
				}
			}
			#cyan{
				animation-name: cyan;
				animation-duration: 5s;
				animation-timing-function: ease-in-out;
				animation-delay: 0s;
				animation-fill-mode: forwards;
				animation-iteration-count: infinite;
				@keyframes cyan {
					0%, 16%  {opacity:1;}
					23%, 100%  {opacity:0;}
				}
			}
			#green{
				animation-name: green;
				animation-duration: 5s;
				animation-timing-function: ease-in-out;
				animation-delay: 0s;
				animation-fill-mode: forwards;
				animation-iteration-count: infinite;
				@keyframes green {
					0%, 26%  {opacity:1;}
					33%, 100%  {opacity:0;}
				}
			}
			#rose{
				animation-name: rose;
				animation-duration: 5s;
				animation-timing-function: ease-in-out;
				animation-delay: 0s;
				animation-fill-mode: forwards;
				animation-iteration-count: infinite;
				@keyframes rose {
					0%, 36%  {opacity:1;}
					43%, 100%  {opacity:0;}
				}
			}
			#red{
				animation-name: red;
				animation-duration: 5s;
				animation-timing-function: ease-in-out;
				animation-delay: 0s;
				animation-fill-mode: forwards;
				animation-iteration-count: infinite;
				@keyframes red {
					0%, 46%  {opacity:1;}
					53%, 100%  {opacity:0;}
				}
			}
			#blue{
				animation-name: blue;
				animation-duration: 5s;
				animation-timing-function: ease-in-out;
				animation-delay: 0s;
				animation-fill-mode: forwards;
				animation-iteration-count: infinite;
				@keyframes blue {
					0%, 56%  {opacity:1;}
					63%, 100%  {opacity:0;}
				}
			}
			#black{
				animation-name: black;
				animation-duration: 5s;
				animation-timing-function: ease-in-out;
				animation-delay: 0s;
				animation-fill-mode: forwards;
				animation-iteration-count: infinite;
				@keyframes black {
					0%, 66%  {opacity:1;}
					83%, 100%  {opacity:0;}
				}
			}
		}

		img{
			max-width: 130px;
		}
	}
	// Global
	.quote-01, .quote-02, .quote-03, .quote-04, .quote-end{
		background: transparent;
		display: flex;
		@extend .align-center;

		&.active {
			height: 100%;
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			padding: 0 3em;
			background: $dark-green;
			//background: linear-gradient(135deg, $light-blue 0%, $dark-green 100%);
			//@include h-gradient ($light-green, $dark-green);
			transition : 0.2s 0.5s background-color ease;
			z-index: 200;

			// ==== grid
			@extend .xxxlarge-7;
			@extend .xlarge-8;
			@extend .xmedium-10;
			@extend .small-24;

			// ==== responsive
			@include breakpoint(medium down) {
				top: 50vh;
				height: calc(100% - 50vh);
			}		

			.quote, .reveral-quote{
				display: block;
			}
			.quote { //  active /!\
				width: 100%;
				@extend .align-self-middle;
				@include content-show (fade-in, 0.5s, 0s);
				// affichage 2 colonnes sur breakpoint medium
				@include breakpoint(medium only) {
					p{
						display: inline-block;
						width: 65%;
					}
					img{
						display: inline-block;
						width: 30%;
						//vertical-align: top;
					}
				}	
			}
		}
		
	}
	// Delete green bg on last slide
	.quote-end.active{
		background:transparent;
	}
	
	button.cs-more, button.cs-more2, button.cs-more3,
	button.cs-more4, button.cs-more5, button.cs-close{
		padding: 13px 20px 14px 20px;
		cursor: pointer;
		font-size: 1.8rem;
		position: absolute;
		top: 0;
		right: 0;
		background: rgba(255,255,255, 0.05);
		svg{
			width: 25px;
			height: 25px;
		}
	}

	button.cs-more2, button.cs-more3, button.cs-more4,
	button.cs-more5, button.cs-close{
		background:transparent;
		background: rgba(0,0,0, 0.1);
		transition : all ease 0.4s;
		.cs-plus-btn{
			transition : all ease 0.4s;
			fill: $light-green;
		}
		&:hover{
			background:$light-green;
			.cs-plus-btn{
				fill: #fff;
			}
		}
	}

	button.cs-more{ 
		right: -15px;
		.cs-plus-btn{
			transition : all ease 0.4s;
			fill: rgba(255,255,255, 0.2);
			fill: $light-green;
		}
		&:hover{
			.cs-plus-btn{
				fill: rgba(255,255,255, 1);
			}
		}
	}


} // End Profile


// =================== Main slide

.cs-slide{
	//background:url(../img/logo-3kdesign.svg) no-repeat calc(-100px - 40%) 0 / 60%, url(../img/logo-3kdesign-reverse.svg) no-repeat 160% calc(90% + 100px) / 60%, url(../img/blur1.1.jpg) no-repeat 50% 50% / cover;
	background:	url(../img/blur1.1.jpg) no-repeat 50% 50% / cover;
	//padding-top: 2em;
	//min-height: 60vh;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	flex-direction : column;

	// ==== Responsive
	// max-height sur mobile
	@include breakpoint(medium down) {
		height: 50vh;
	}

	.cs-logo-cyril{
		max-width: 30vw;
		max-height: 70%;
		margin-left: 4%;
		@include breakpoint(xsmall down) {
			max-width: 85vw;
			height: auto;
		}
	}

	// order
	@extend .small-order-1;
	// animation ($animation-name, $delay, $duration)
	@include content-show (fade-in, 3.4s, 1s); //1.4s

}






/*
==================================================
====================== Intro            			
==================================================
*/

.cs-intro{
	//background:#fff;
	//@include content-show (fade-in, 3s, 0);
	position: relative;
	//height: 420px;
	.reveral-intro{
		//height: 420px;
		// ($name, $color, $zindex, $delay, $duration, $reverse);
		@include block-reveral (slide-v, #fff, 10, 3.4s, 0.4s, normal); //1.4
		animation-fill-mode: forwards;
		z-index: 10;
	}


	.cs-devices{
		position: relative;
		height: 420px;
		z-index: 11;
		@extend .show-for-medium; // Under 640px load owlcarousel

		// ==== responsive
		@include breakpoint(xlarge down) {
			height: 360px;
		}
		@include breakpoint(large down) {
			height: 290px;
		}
		@include breakpoint(xmedium down) {
			height: 40vw;
		}
		@include breakpoint(medium down) {
			height: 40vw;
			margin-top: 80px;
		}
		img{
			display: inline-block;
			position: absolute;
			&.shadow-devices{
				width: 100%;
				height: 50%;
				bottom: 0;
				left: 0;
				@include content-show (fade-in, 3.5s, 2s); //1.5
			}
			&.minitel{
				width: 290px;
				top: 100px;
				width: 33%;
				max-width: 380px;
				left: 5%;
				@include content-show (fade-from-right, 4.3s, 0.2s); //2.3
			}
			&.iphone{
				width: 140px;
				top: 70px;
				width: 16%;
				max-width: 180px;
				left: 27%;
				@include content-show (fade-from-right, 4.1s, 0.2s); //2.1
			}
			&.gameboy{
				left: 285px;

				top: -3vh;
				@media (min-height: 800px) and (min-width: 2100px){
					top: -2vh;
				}
				width: 350px;
				width: 42%;
				max-width: 400px;
				left: 32%;
			@include content-show (fade-from-bottom, 4s, 0.2s); //2
		}
		&.mabrouk{
			left: 470px;
			top: 80px;
			width: 450px;
			width: 50%;
			max-width: 570px;
			left: 49%;
			@include content-show (fade-from-left, 4.2s, 0.2s); //2.2
		}
	}
		// order
		// @extend .xmedium-order-2;
		// @extend .medium-order-1;

		// ==== grid
		@extend .xxxlarge-14;
		@extend .xxxlarge-offset-2;
		@extend .xlarge-16;
		@extend .large-16;
		@extend .small-24;
	}

	// Slider Owl Carousel
	.cs-devices-mobile{
		z-index: 11;
		margin-top: 40px;
		img{
			height: 250px;
			width: auto;
			margin: auto;
			&.iphone{
				height: 230px;
			}
		}
		@extend .small-24;
		@extend .hide-for-medium;
	}




	.cs-text{
		z-index: 11;
		font-family:  'SourceSerifPro', Georgia;
		text-align: center;
		font-size: 1.4rem;
		color: #999;
		// min-height: 200px; // A remettre si Microwave ON
		@extend .align-self-middle;
		@include content-show (fade-from-left, 4.6s, 1s); // 2.6
		@extend .xxxlarge-6;

		.show-for-large{
			@include breakpoint(large down) {
				display: none;
			}
		}
		.microwave{
			width: 46px;
			display: inline-block;
			margin-top: 10px;
		}

		// ==== responsive
		@include breakpoint(xxlarge down) {
			padding-right: 5em;
		}
		@include breakpoint(xlarge down) {
			padding-right: 2em;
		}
		@include breakpoint(large down) {
			padding-right: 30px;
			padding-left: 0;
			font-size: 1.2rem;
		}
		@include breakpoint(xmedium down) {
			padding-right: 2em;
			padding-left: 2em;
			font-size: 1.4rem;
			@include content-show (fade-from-top, 4.6s, 1s); // 2.6
		}
		@include breakpoint(small down) {
			margin-bottom: 30px;
			font-size: 1.2rem;
			min-height: 100px;
		}
	}
}



/*
==================================================
====================== Skills            			
==================================================
*/

.skills{
	.consulting, .webdesign, .development{
		background:#444;
		text-align: center;
		padding: 3em 2em;
		@include breakpoint(xsmall down) {
			padding: 2em;
		}
		@include breakpoint(small down) {
			padding: 1.5em;
		}
		img{
			max-width: 55px;
			min-height: 60px;
			display: block;
			margin: 0 auto 30px;
			@include breakpoint(small down) {
				margin-bottom: 15px;
			}
			@include breakpoint(xsmall only) {
				display: inline-block;
				float: left;
				margin: 0;
			}
		}
		h3{
			color: #999;
			font-weight: 400;
			font-size: 1.1rem;
			@include breakpoint(xxlarge down) {
				font-size: 1rem;
			}
			@include breakpoint(xmedium down) {
				font-size: 1rem;
			}
			@include breakpoint(xsmall only) {
				display: inline-block;
				text-align: left;
				float: left;
				max-width: 75%;
				margin-left: 2rem;
			}
			@include breakpoint(small down) {
				font-size: 0.8rem;
			}
		}
		
		@extend .large-8;
		@extend .medium-12;
		@extend .small-24;
	}
	.webdesign{
		background:#333;
	}
	.development{
		background:#111;
		@extend .medium-24;
	}
}




/*
==================================================
====================== Clients            			
==================================================
*/


.clients{
	background: #fff;

	.block-logos{
		padding: 3em 3em 4rem 3rem;
		@include breakpoint(xsmall down) {
			padding: 2em;
		}
		h2{
			margin-bottom: 2em;
			color: #444;
			@include breakpoint(xxlarge) {
				margin-bottom: 1.5rem;
			}
			@include breakpoint(xsmall down) {
				font-size: 1.4rem;
			}
			@include breakpoint(small down) {
				font-size: 1rem;
			}
		}
		.columns{
			text-align: center;
			@extend .xxlarge-8;

			&.fox{
				@extend .show-for-medium;
			}
			&.hsbc{
				@extend .show-for-xsmall;
			}


			@include breakpoint(xxlarge) {
				img{
					max-height: 70px;
				}
				&.meteofrance, &.fox, &.peugeot {
					img{
						margin-bottom: 2rem;
					}
				}
			}
		}

		img{
			max-height: 80px;
			//margin-right: 2.5em;
			display: inline-block;
			align-self: flex-end;
		}

		@extend .xxxlarge-12;
		@extend .xxlarge-10;
		@extend .small-24;
	}

	.reviews {
		padding: 3rem 0;
		background-image: radial-gradient(1px at -100px 50%, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 200px);
		
		@extend .xxxlarge-12;
		@extend .xxlarge-14;
		@extend .small-24;

		@include breakpoint(xlarge down) {
			//padding: 2rem 0;
			background-image: radial-gradient(1px at 50% -30vw, rgba(0, 0, 0, 0.3) 0%, transparent 50vw);
		}

		.avatar{
			@extend .xxxlarge-8;
			@extend .xxlarge-8;
			@extend .xlarge-7;
			@extend .xmedium-8;
			@extend .small-24;

			@include breakpoint(medium down) {
				text-align: center;
			}
		}

		.avatar-photo {
			margin: 2rem 0 2rem;
			width: 200px;
			height: 200px;
			border-radius: 50%;
			-webkit-border-radius: 50%;
			overflow: hidden;
			display: inline-block;
			float: right;
			box-shadow: 0 0 0 5px #fff;
			img{
				height: 200px;
				width: auto;
				border-radius: 50%;
			}
			@include breakpoint(xlarge down) {
				margin: 0;
			}
			@include breakpoint(medium down) {
				float: none;
				margin: 0;
				width: 150px;
				height: 150px;
				img{
					height: 150px;
					width: auto;
				}
			}
		}

		.cs-text{
			font-family:  'SourceSerifPro', Georgia;
			color: #808080;
			font-size: 1.3rem;
			//padding-top: 1rem;
			padding-right: 3rem;
			padding-left: 2rem;
			background:url(../img/ico-quote.svg) no-repeat 0 0;
			background-size: 25px;

			@include breakpoint(medium down) {
				text-align: center;
				padding: 1rem 3rem;
				background-position: 10% 0;
				background-size: 30px;
				font-size: 1.2em;
			}
			@include breakpoint(small down) {
				padding: 1.5rem;
				padding-bottom: 0;
				font-size: 1.1rem;
			}
		}
		.cs-profile{
			padding-left: 2rem;
			@include breakpoint(medium down) {
				text-align: center;
				padding-right: 1rem;
				padding-left: 1rem;
			}

			& span, & a{
				font-family: 'Titillium Web';
				font-weight: 600;
				font-size: 1.1rem;
				line-height: 1;
			}
			.cs-name{
				color: $dark-grey;
				font-size: 1.4rem;
			}
			.cs-job{
				color: #808080;
			}
			a.cs-compagny{
				color: $dark-green;
			}
		}
	} // End review
}





/*
==================================================
====================== Portfolio            			
==================================================
*/

.cs-portfolio-home{
	background:	#fff;

	// Global style for portfolio
	.cs-boostravel, .cs-otorim, .cs-citydrive {
		&>.row{
			height: 500px;
			.columns{
				@extend .align-self-middle;
			}
		}
		a.cs-infos{
			display: block;
			margin: auto;
			transition: all ease 0.4s;
			padding: 1.2rem 2rem;
			padding-right: 0.8rem;
			box-shadow: 0 0 0 0 rgba(255,255,255, 0) inset;
			@include breakpoint(medium down) { // Désactive le lien en mobile
				display: none;
			}
			svg{
				display: inline-block;
				margin: 0.6rem 0;
			}
			.logo{
				fill: #fff;
			}

			& .text{
				opacity: 0;
				visibility: hidden;
				height: 0px;
				padding-left: 0;
				margin-left: -15px;
				margin-top: -15px;
				h3{margin-top: 0;}
				p{
					color: $dark-grey;
					margin: 0;
					font-size: 1rem;
					padding-right: 1.5rem;
				}
			}

			&:hover{
				width: 570px;
				background:#fff;
				& .text{@include content-show (cta-text, 0.4s, 1s);}
				@media (min-width: 1200px) and 	(max-width: 1300px){
					width: 530px;
				}
			}

		} // -- End cs-infos
		

		// Responsive / Mobile ====> /!\ MAJ n'est plus affichée, un autre <a> est créé pour le mobile
		@include breakpoint(medium down) {
			&>.row{
				height: 70vh;
			}
			a.cs-infos{
				&:hover{
					width: auto;
					background: transparent;
					box-shadow: 0 0 0 0 rgba(255,255,255, 0) inset !important;
					& .text{display: none;}
					.logo, .logo-top, .logo-bottom{
						fill: #fff !important;
						text-align: center;
						padding-left: 0 !important;
					}
				}
			}
		}
		
		// Nouvelle version mobile
		a.cs-infos-mobile{
			@include breakpoint(xmedium) {display: none;}
			@include breakpoint(medium down) {
				position: relative;
				width: 100%;
				height: 70vh;
				display: inline-block;
				text-align: center;
				svg{
					display: inline-block;
					width: 60px;
					fill: #fff;

				}
				.text{
					margin: auto;
					bottom:10px;
					position: absolute;
					width: 100%;
					color: #fff;
					h3{
						font-size: 1.2rem;
						margin-bottom: 0.5rem;
					}
					p{
						font-size: 0.9rem;
						padding: 0 2rem;
					}
				}
			}
		}
	} // -- End Global style portfolio


	// Boostravel 
	.cs-boostravel {

		background:url(../img/bg-boostravel-home.jpg) no-repeat 50% 50% / cover;

		// --logo-width : 70px; // variables css incompatibles ie/edge
		//transition: all ease-in-out 8s;
		//transform: translate3d(0,0,0);
		&:hover{
			//background-size: auto 110%;
		}

		a.cs-infos{
			$logo-width: 70px;
			width: calc(#{$logo-width} + 4rem);
			//width: calc(var(--logo-width) + 4rem); // incompatible ie/edge

			svg{
				width: $logo-width;
				//width: var(--logo-width); // incompatible ie/edge
			}
			& .text{
				@extend .xsmall-19;
				h3{
					color: #F17221;
				}
				span{
					color: #F17221;
				}
			}
			&:hover{
				box-shadow: 0 -5px 0 #F17221 inset;
				.logo{
					fill: #F17221;
				}
			}		
		}
		@extend .small-24;
	}

	// Otorim 
	.cs-otorim {

		background:url(../img/bg-otorim-home.jpg) no-repeat 50% 50% / cover;

		a.cs-infos-mobile svg{width: 90px;}

		a.cs-infos{
			$logo-width: 110px;
			width: calc(#{$logo-width} + 4rem);
			svg{
				width: $logo-width;
			}
			& .text{
				@extend .xsmall-17;
				h3{
					color: #CA3000;
				}
				span{
					color: #CA3000;
				}
			}
			&:hover{
				box-shadow: 0 -5px 0 #CA3000 inset;
				.logo{
					fill: #CA3000;
				}
			}		
		}
		@extend .xlarge-12;
		@extend .small-24;
	}

	// Citydrive 
	.cs-citydrive {

		background:url(../img/bg-citydrive-home.jpg) no-repeat 50% 50% / cover;

		a.cs-infos{
			$logo-width: 70px;
			width: calc(#{$logo-width} + 4rem);
			svg{
				width: $logo-width;
			}
			& .text{
				@extend .xsmall-19;
				h3{
					color: #32B3C0;
				}
				span{
					color: #F18534;
				}
			}
			&:hover{
				box-shadow: 0 -5px 0 #32B3C0 inset;
				.logo-top{
					fill: #32B3C0;
				}
				.logo-bottom{
					fill: #F18534;
				}
			}		
		}
		@extend .xlarge-12;
		@extend .small-24;
	}
}





/*
==================================================
====================== Hire me            			
==================================================
*/
.hire-me{
	background:#fff;
	text-align: center;
	padding: 3rem;
	@include breakpoint(xsmall down) {
		padding: 2rem;
	}
	p{
		font-family:  'SourceSerifPro', Georgia;
		text-align: center;
		font-size: 1.6rem;
		color: #999;
		@include breakpoint(medium down) {
			font-size: 1.4rem;
		}
		@include breakpoint(xsmall down) {
			font-size: 1.3rem;
		}
		@include breakpoint(small down) {
			font-size: 1.1rem;
		}
	}
	a{
		font-size: 1.2rem;
		text-transform: uppercase;
		font-weight: 600;
		padding: 10px 18px;
		color: $dark-green;
		box-shadow: 0 0 0 2px $dark-green;
		display: inline-block;
		transition: all ease 0.4s;
		margin-bottom: 1rem;
		&:hover{
			background: #111;
			color: $light-green;
			box-shadow: 0 0 0 6px #eee;
		}
		@include breakpoint(xsmall down) {
			font-size: 1.2rem;
		}
	}
}


/*
==================================================
====================== Footer            			
==================================================
*/

footer .row{
	height: 250px;
	background-color: #1a1a1a;
	border-top: 3px solid #1a1a1a;
	background-image: radial-gradient(ellipse at 50% -20%,
		rgba(255, 255, 255, 0.3) 0%,
		rgba(255, 255, 255, 0.1) 40%,
		rgba(255, 255, 255, 0) 70%);
	.columns{
		@extend .align-self-middle;
		text-align: center;
		img{
			max-height: 90px;
			width: auto;
		}
		&.logo{
			//@extend .large-8;
		}
		h3{
			font-size: 0.9rem;
			color: #666;
			margin-top: 30px;
			font-family: 'Titillium Web';
			font-weight: 500;
			text-transform: none;
			& a{
				color: #999;
				transition: all ease 0.4s;
				&:hover{
					color: $light-green;
				}
			}
		}
	}

}

/* ################################################################################# 
/* ################################################################################# 
/* ################################################################################# 
/* ################################################################################# 
/* ################################################################################# 
/* ################################################################################# 
*/


// main: app.scss


/*
==================================================
====================== Boostravel            			
==================================================
*/

/*
==================================================
====================== Header            			
==================================================
*/

.bs-detail{
	background: $dark-grey;
	color: #fff;
	padding: 2rem 3rem;

	// ==== animation
	@include content-show (fade-in, 3.4s, 0); //0.4

	h1{
		font-size: 1.4rem;
		margin-bottom: 2rem;
		margin-top: 1rem;
	}

	// ==== grid
	@extend .xxxlarge-6;
	@extend .xlarge-8;
	@extend .xmedium-10;
	@extend .small-24;

	// ==== order
	@extend .small-order-2;
	@extend .xmedium-order-1;

	//==== Responsive
	@include breakpoint (medium down) {
		padding: 1.5rem 2rem;	
		h1{
			margin-top: 0;
			margin-bottom: 1rem;
		}
	}
	@include breakpoint (small down) {
		p{
			font-size: 1rem;
		}
	}
}

.bs-visual{
	
	background:url(../img/boostravel/bg-boostravel.jpg) no-repeat 50% 40% / cover;
	height: 500px;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	flex-direction : column;

	// order
	@extend .small-order-1;
	@extend .align-self-stretch;

	// ==== animation
	@include content-show (fade-in, 3.4s, 0); //0.4

	img{
		margin-top: -10rem;
		margin-left: -55%;
		height: auto;
		@include breakpoint (small down) {
			max-width: 140%;
			margin-left: -80%;
		}
	}

	@include breakpoint (xmedium down) {
		//height: 40vh;
	}
	@include breakpoint (medium down) {
		height: 60vh;
	}
}



/*
==================================================
====================== Magazine            			
==================================================
*/


.bs-magazine{
	background: #fff;
	background-image: radial-gradient(1px at 50% -30vw, rgba(0, 0, 0, 0.3) 0%, transparent 50vw);
	position: relative;
	padding: 0 4rem;
	text-align: center;
	text-shadow: 0  2px 0 rgba(255,255,255, 0.8);

	@include content-show (fade-in, 3.5s, 2s); //1.5

	@include breakpoint(xsmall down) {
		padding: 0 2.5rem;
	}
	@include breakpoint(small down) {
		padding: 0 1.5rem;
	}

	.text{
		font-family:  'SourceSerifPro', Georgia;
		text-align: center;
		color: #999;

		@extend .large-13;
		@extend .xmedium-12;
		@extend .small-24;

		@include breakpoint(xxlarge) {
			padding: 6.5rem 5rem 6.5rem 14rem;
			font-size: 1.4rem;
		}
		@include breakpoint(xlarge down) {
			padding: 4.5rem 3rem;
			font-size: 1.3rem;
		}
		@include breakpoint(large down) {
			padding: 3.5rem 0rem;
			font-size: 1.2rem;
		}
		@include breakpoint(medium down) {
			order : 2;
			margin-top: 13rem;
		}
		@include breakpoint(xsmall down) {
			margin-top: 35vw;
		}
		@include breakpoint(small down) {
			margin-top: 35vw;
			font-size: 1.1rem;
		}



	}

	.image{
		position: absolute;
		margin-top: -80px;
		margin-left: auto;
		margin-right: auto;
		//margin-left: -2rem;


		img{
			width: 100%;
			max-width: 450px;
		}


		@include breakpoint(xxlarge ) {
			img{
				max-width: 600px;
			}
		}
		@include breakpoint(xxxlarge ) {
			img{
				max-width: 550px;
			}
		}
		@include breakpoint(xmedium down) {
			margin-top: -40px;
			margin-left: -3rem;
			img{
				width: 80%;
			}
		}
		@include breakpoint(medium down) {
			margin-left: 1vw;
			img{
				width: 100%;
			}
		}
		@include breakpoint(xsmall down) {
			margin-left: -2rem;
			img{
				width: 100%;
			}
		}
		@include breakpoint(small down) {
			margin-left: -3rem;
			margin-top: -30px;
			img{
				width: 90%;
			}
		}

	}
}


/*
==================================================
====================== Plane animation            			
==================================================
*/

.plane{
	//@extend .large-12;
}
.plane-animation{
	.container{
		//@extend .large-10;
		height: 300px;
		@include h-gradient(#F68700, #DA3A2D);
		//border-radius: 50%;
		overflow: hidden;
		position: relative;
	}

	.cloud1, .cloud2, .cloud3, .cloud4 {
		position: absolute;
		background: url(../img/ico-cloud.svg) no-repeat;
		//background: url(../img/cloud.png) no-repeat;
		width: 1000px;
		height: 800px;	
		animation-timing-function: linear;
		animation-iteration-count: infinite;
	}
	.cloud1{
		transform: rotate(-25deg) translateZ(0);
		//Animation
		animation-name: cloud1;
		animation-duration: 25s;
		
	}
	.cloud2{
		top: -2000px;
		opacity: 0.4;
		transform: rotate(155deg) translate3d(0,0,0);
		//Animation
		animation-name: cloud2;
		animation-duration: 35s;
		@extend .show-for-large;
	}
	.cloud3{
		top: 0px;
		opacity: 0.4;
		transform: rotate(155deg) translate3d(0,0,0);
		//Animation
		animation-name: cloud3;
		animation-duration: 38s;
		@extend .show-for-large;
	}
	.cloud4{
		top: 0px;
		transform: rotate(-25deg) translate3d(0,0,0);
		//Animation
		animation-name: cloud4;
		animation-duration: 24s;
	}
	.plane{
		width: 800px;
		height: 100px;
		background: url(../img/ico-plane.svg) no-repeat;
		position: absolute;
		top: 250px;
		left: calc(50% - 700px);
		//Animation
		animation-name: plane;
		animation-duration: 2s;
		animation-delay: 0;
		animation-direction: alternate;
		animation-timing-function: ease-in-out;
		animation-iteration-count: infinite;
	}.plane-shadow{
		width: 60px;
		height: 60px;
		background: url(../img/ico-plane-shadow.svg) no-repeat;
		position: absolute;
		top: calc(50% - 25px);
		left: calc(50% - 5px);
		opacity: 0.2;
		//Animation
		animation-name: plane-shadow;
		animation-duration: 2s;
		animation-delay: 0;
		animation-fill-mode: forwards;
		animation-direction: alternate;
		animation-timing-function: ease-in-out;
		animation-iteration-count: infinite;
	}
}


/*
==================================================
====================== List skill       			
==================================================
*/

.bs-skills{
	.skill-list{
		background: $dark-grey;
		padding: 3rem 3rem 2rem 3rem;
		color: #fff;

		@extend .large-12;
		@extend .small-24;
		//@extend .show-for-xmedium;


		ul{
			padding: 0;
			margin: 0;

			.columns{
				@extend .large-24;
				@extend .xmedium-12;
				@extend .small-24;
			}
		}
		li{
			list-style: none;
			display: block;
			margin-bottom: 1.5rem;
			font-size: 'Montserrat', arial;
			text-transform: uppercase;
			font-size: 1.2rem;
			&:last-child{
				//margin-bottom: 0;
			}
			span{
				color: $dark-green;
				margin-right: 2rem;
				font-size: 1.5rem;
				vertical-align: baseline;
			}
		}

		@include breakpoint(small down) {
			padding: 2rem 1rem 1rem 1rem;
			li{
				font-size: 0.9rem;
				span{
					margin-right: 1rem;
				}
			}
		}
	}
}





/*
==================================================
====================== Wireframe       			
==================================================
*/

.bs-wireframe{
	text-align: center;
	text-shadow: 0 0 20px rgba(0,0,0,0.6);
	height: 50vh;
	min-height: 300px;
	background:url(../img/boostravel/boostravel-wireframe.jpg) 50% 50% / cover;
	background-attachment: fixed;

	@extend .align-middle;

	.number{
		color: #fff;
		padding: 3.3rem 1rem;
		border-radius: 50%;
		height: 200px;width: 200px;
		display: inline-block;
		@include h-gradient($light-green, $dark-green);

		span{
			font-family: 'Montserrat', arial;
			font-size: 4rem;
			font-weight: 700;
			line-height: 3rem;
		}
	}
}



/*
==================================================
====================== UI     			
==================================================
*/

.ui{

	// ==== Wallpaper
	.wallpaper{
		background:url('../img/boostravel/bg03.jpg') no-repeat 50% 100% / cover;
		height: 50vh;
		min-height: 400px;
		max-height: 620px;
		display: flex;
		position: relative;
		z-index: 50;

		.punchline{
			position: absolute;
			left: 30%;
			top: 80px;
		}
		.laptop{
			width: 60%;
			position: absolute;
			bottom: -70px;
			align-self: flex-end;

			@extend .hide-for-small-only;
		}
		.mobile{
			width: 15%;
			position: absolute;
			bottom: -90px;
			left: 50%;
			align-self: flex-end;
		}

		@include breakpoint(xxxlarge) {
			min-height: calc( (50vh + 35vw) / 2 );
		}

		@include breakpoint(large down) {
			.punchline{
				width: 350px;
				left: 25%;
			}
			.laptop{
				width: 75%;
				bottom: -50px;
			}
			.mobile{
				width: 20%;
				bottom: -70px;
				left: 50%;
			}
		}

		@include breakpoint(medium down) {
			.laptop{
				min-width: 560px;
				bottom: -50px;
				left: -5%;
			}
			.mobile{
				width: 25%;
				min-width: 170px;
				bottom: -70px;
				left: 53%;
			}
		}
		@include breakpoint(xsmall down) {
			min-height: auto;
			height: 300px;
			.punchline{
				width: 300px;
				top: 40px;
				left: 15%;
			}
			.laptop{
				min-width: 500px;
				width: 75%;
				bottom: -70px;
			}
			.mobile{
				width: 30%;
				min-width: auto;
				bottom: -90px;
				left: 60%;
			}
		}
		@include breakpoint(small down) {
			.punchline{
				width: 80%;
				left: 10%;
			}
			.mobile{
				width: 70%;
				left: 15%;
				bottom: auto;
				top: 120px;
			}
		}
	} /* End Wallpaper */



	// ==== Color & Fonts
	.assets{
		min-height: 50vh;
		background: #fff url(../img/boostravel/world-map.png) no-repeat 50% 50% / cover;
		position: relative;
		text-align: center;
		overflow: hidden;

		@extend .align-center;
		@include breakpoint(large) {
			height: calc( 0 + 80vh);
			height: 400px;
		}

		.shadow{
			height: 100px;
			width: 100%;
			position: relative;
			@include v-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0));
		}
		.shadow-white{
			height: 100px;
			width: 100%;
			position: absolute;
			bottom: 0;
			@include v-gradient(rgba(255,255,255,0), rgba(255,255,255,1));
		}
		
		// Colors
		.colors{
			text-align: center;
			
			@include breakpoint(small) {
				margin-top: calc( 100vw / 2);
			}
			@include breakpoint(xsmall) {
				margin-top: 80px;
			}
			@include breakpoint(xlarge) {
				margin-top: 120px;
			}
			

			.row{
				@extend .align-spaced;
			}

			@extend .small-24;
			@extend .xsmall-22;
			@extend .xmedium-12;
			@extend .large-13;
			@extend .xxlarge-11;

			.columns{
				margin-top: 20px;
				@extend .small-16;
				@extend .xsmall-12;
				@extend .medium-8;
				@extend .xmedium-12;
				@extend .large-8;
			}
		}
		
		// Fonts
		.fonts{
			margin-top: 20px;
			@include breakpoint(xsmall) {
				margin-bottom: 100px;
			}
			@include breakpoint(xmedium) {
				margin-top: 100px;
			}
			@include breakpoint(xlarge) {
				margin-top: 140px;
				margin-left: 3vw;
			}
			
			@extend .small-18;
			@extend .medium-12;
			@extend .xmedium-8;
			@extend .large-8;
			@extend .xxlarge-7;
		}
		
	}


} /* End UI */



/*
==================================================
====================== Masonry     			
==================================================
*/

	// http://masonry.desandro.com/options.html

/* ---- grid ---- */
.bs-masonry{
	background: #fff;
	padding-right: 20px;
	padding-left: 20px;
	padding-bottom: 60px;
	@include breakpoint(xxlarge) {
		padding-right: 40px;
		padding-left: 40px;
	}

	/* clearfix */
	.grid:after {
		content: '';
		display: block;
		clear: both;
	}



	@media screen and (min-width: 1020px) and (min-height: 100px) {
		margin-top: -20vh; 
	}



	/* ---- grid-item ---- */

	--grid-sizer : calc(100% / 6);

	@media screen and (max-width: 1200px) {
		.grid-item, .grid-sizer { 
			--grid-sizer : calc(100% / 4);
		}
	}
	@media screen and (max-width: 900px) {
		.grid-item, .grid-sizer {
			--grid-sizer : calc(100% / 3);
		}
	}
	@media screen and (max-width: 700px) {
		.grid-item, .grid-sizer {
			--grid-sizer : calc(100% / 2);
		}
	}


	.grid-sizer{
		width: 10%;
		//width: calc(100% / 6);
		width: var(--grid-sizer);
	}
	
	.grid-item {
		float: left;
		padding: 20px;
		width: 20%;
		width: calc(100% / 6);
		width: var(--grid-sizer);
		opacity: 0.8;
		transition: opacity ease 0.3s;
		&:hover{
			opacity: 1;
		}
		@include breakpoint(xxlarge) {
			padding: 40px;
		}

		&.w1{width: calc( 100% / 6);}
		&.w2{width: calc( 100% / 6 * 2);}
		&.w3{width: calc( 100% / 6 * 3);}
		&.w4{width: calc( 100% / 6 * 4);}
		&.w5{width: calc( 100% / 6 * 5);}
		&.w6{width: calc( 100% / 6 * 6);}

		&.w1{width: calc( var(--grid-sizer) *1);}
		&.w2{width: calc( var(--grid-sizer) * 2); @extend .show-for-medium;}
		&.w3{width: calc( var(--grid-sizer) * 3); @extend .show-for-medium;}
		&.w4{width: calc( var(--grid-sizer) * 4);}
		&.w5{width: calc( var(--grid-sizer) * 5);}
		&.w6{width: calc( var(--grid-sizer) * 6);}

		@media screen and (max-width: 700px) {
			&.w2{width: calc( var(--grid-sizer) * 1);}
			&.w3{width: calc( var(--grid-sizer) * 2);}
		}
		@media screen and (max-width: 460px) {
			&.w2{width: calc( var(--grid-sizer) * 2);}
			&.w1{width: calc( var(--grid-sizer) * 2);}
		}

		&.w30 {width: 30%;}
		&.w40 {width: 40%;}
		&.w60 {width: 60%;}
		&.w70 {width: 70%;}
		img:not(.no-shadow) {
			box-shadow: 0 0 20px rgba(0,0,0,0.3);
		}
	}


	// .grid-item--height2 { height: 200px; }
	// .grid-item--height3 { height: 260px; }
	// .grid-item--height4 { height: 360px; }

}


/*
==================================================
====================== Hire me            			
==================================================
*/
.bs-hire-me{
	background:#eee;
	background-image: radial-gradient(1px at 50% -30vw, rgba(0, 0, 0, 0.3) 0%, transparent 40vw),
	radial-gradient(1px at 50% 300px, rgba(255, 255, 255, 1) 0%, transparent 40vw);
	a{
		//background: #fff;
		&:hover{
			box-shadow: 0 0 0 3px #fff;
		}
	}
}


/*
==================================================
====================== Other portfolio     			
==================================================
*/

.bs-portfolio{
	.cs-boostravel, .cs-otorim, .cs-citydrive {
		&>.row{
			height: 300px;
		}
	}

}

